import React, { Component } from "react";
import ContactUs from 'pages/contact_us';
import {COMPANY_NAME} from 'utils/constants';
import {trackEvent, EventNames} from 'utils/mixpanel';


class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContactUs: false,
        };
        this.setContactUsDisplay = this.setContactUsDisplay.bind(this);
    }

    setContactUsDisplay(value) {
        this.setState({displayContactUs: value });
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'about_us'})
        document.title = "About Renolition Inc"
    }

    render() {

        return(
            <div class="aboutUsEvenlope topOfPageMargin">
                <div class="aboutUs">
                    
                    <ContactUs 
                        displayStatus={this.state.displayContactUs} 
                        changeDisplayStatus={this.setContactUsDisplay}
                    />
                    <div class="grid" >
                        <div class="content">
                            <div class="title">About {COMPANY_NAME}</div>
                            <div class="body mt-5">
                                <p>
                                    <strong>{COMPANY_NAME}</strong> harnesses the power of advanced AI technology to simplify and 
                                    accelerate the process of working with <strong>planning and zoning codes</strong>. Tailored for professionals such 
                                    as lawyers, architects, engineers, designers, and developers, our platform streamlines the discovery 
                                    and interpretation of complex regulatory frameworks.
                                </p>
                                <p>
                                    By utilizing Renolition, professionals can quickly access and pinpoint relevant documents and 
                                    sections for their specific projects, significantly reducing the time spent on research. This 
                                    efficiency not only expedites the due diligence process but also helps to streamline project 
                                    approvals and permitting, ensuring that projects move forward without unnecessary delays.
                                </p>
                                {/* <p>
                                    Building codes determine how buildings need to be constructed to be safe for its occupants. 
                                    As building codes evolve and become more complex, identifying the right sections that apply to a project becomes more difficult.
                                </p> */}
                                {/* <p>
                                    Renolition makes identifying relevant planning and building code sections seamless and ensures final construction plans get approved faster.
                                </p> */}
                                <p>
                                Headquartered in San Francisco, California, Renolition Inc. is committed to driving innovation in regulatory compliance. 
                                For more information or to share any feedback, feel free to reach out to us at&nbsp;<a onClick={ () => this.setContactUsDisplay(true) }>contact us.</a> 
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        )
    }
}

export default AboutUs;