/* global google */
// do not delete above line. Needed for google address to function

import React, { Component, useState, Fragment, useRef, useEffect } from "react";
import { PLANNING_PROPERTY_INFO } from 'utils/constants';
import DatePicker from "react-datepicker"; // https://reactdatepicker.com/
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Spinner, Modal, ButtonGroup, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { generic_post_api } from "api/generic_api";
import { SFPlanningCode } from "./planning_code_search_680";


var get_yesterday_date = () => {
    var today = new Date();
    today.setDate(today.getDate() - 1);

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
}

const Stages = {
    QUESTIONS: 'questions',
    RESULT: 'result',
}

const answer_1 =     <Card>
                        <Card.Body>
                            
                            <div class="answerEnvelop">
                                <div class="mb-2">
                                    According to <strong>SEC. 260</strong>, certain features are exempt from height limitations, provided specific conditions are met.
                                </div>

                                <div class="fw-bold">
                                    Horizontal Area Limitation
                                </div>
                                <ul>
                                    <li>The total horizontal area of all exempt features must not exceed 30% of the roof's horizontal area.</li>
                                    <li>Within the first 10 feet of depth from the building's front wall, the sum of the horizontal areas of these
                                        features must not exceed 20% of the roof's horizontal area in that section.
                                    </li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Specific Exempt Features
                                </div>
                                <ul>
                                    <li>Includes elevator, stair, and mechanical penthouses, fire towers, skylights, and dormer windows.</li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Height Limitations for Exempt Features
                                </div>
                                <ul>
                                    <li>Exemption is limited to the top 16 feet of these features if the building height limit is 65 feet or less.
                                    </li>
                                    <li>If the building height limit is over 65 feet, the exemption applies to the top 20 feet of these features.
                                    </li>
                                    <li>For elevator penthouses, the exemption is limited to the top 16 feet, restricted to the footprint of the
                                        elevator shaft, regardless of the building's height limit.
                                    </li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Design Consistency
                                </div>
                                <ul>
                                    <li>Elevator penthouses in Residential Districts must comply with the "Residential Design Guidelines" as adopted
                                        by the Planning Commission.
                                    </li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Further Height Exemption
                                </div>
                                <ul>
                                    <li>The Zoning Administrator may grant additional height exemptions for elevator penthouses in buildings with
                                        height limits over 65 feet after a public hearing.
                                    </li>
                                    <li>This exemption is only permissible if necessary to comply with state or federal laws or regulations.</li>
                                    <li>Requests for height exemptions in Residential or Neighborhood Commercial Districts must follow the
                                        neighborhood notification requirements of Section 311 of the Code.
                                    </li>
                                </ul>
                                <div class="mt-2 fw-bold">Sources:</div>
                                <ul>
                                    <li><div role="button">SEC. 260: Height Limits: Measurement <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>

const answer_2 =     <Card>
                        <Card.Body>
                            
                            <div class="answerEnvelop">
                                <div class="mb-2">
                                    According to <strong>SEC. 135</strong>, the slope of any usable open space, whether it is private or common, must not exceed five percent.
                                </div>

                                <div class="mt-2 fw-bold">Sources:</div>
                                <ul>
                                    <li><div role="button">SEC. 135: Usable open space for dwelling units and group housing <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>

const answer_3 =     <Card>
                        <Card.Body>
                            
                            <div class="answerEnvelop">
                                <div class="mb-2">
                                <ul>
                                    <li>A gym is classified as a "Retail Sales and Service Use," which includes health clubs, fitness centers, and exercise facilities.</li>
                                    <li>Gym use is permitted as part of Retail Sales and Service, with a maximum of 90,000 Gross Square Feet allowed per lot.</li>
                                    <li>If the gym qualifies as "Formula Retail" (as per Planning Code Section 303.1), it requires a Conditional Use Authorization from the Planning Commission.</li>
                                    <li>The property must maintain an "active use" on the ground floor, and a gym satisfies this requirement.</li>
                                    <li>The property is within the Central SoMa Special Use District, which does not impose any specific restrictions on using the property as a gym.</li>
                                </ul>

                                </div>

                                <div class="mt-2 fw-bold">Sources:</div>
                                <ul>
                                    <li><div role="button">SEC. 102: Definitions <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                    <li><div role="button">SEC. 303.1: Formula retail uses <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                    <li><div role="button">ZA letter of determination (November 22, 2023) <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/LoD+Response+Letter+-+635+Brannan+Street+-+2023-009722ZAD.pdf" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></li>
                                </ul>
                            </div>
                        </Card.Body>
                        </Card>

class PlanningCodeSearch633 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_stage: Stages.QUESTIONS,
            height_bulk: '',
            use_district: '',
            special_use_districts: '',
            formatted_address: '',
            all_code_sections: null,
        };
        this.setStage = this.setStage.bind(this);

        this.setHeightBulk = this.setHeightBulk.bind(this);
        this.setUseDistrict = this.setUseDistrict.bind(this);
        this.setSpecialUseDistricts = this.setSpecialUseDistricts.bind(this);
        this.setFormattedAddress = this.setFormattedAddress.bind(this);
        this.setAllCodeSections = this.setAllCodeSections.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'planning_code_search'});
    }

    setStage(stage) {
        this.setState({ current_stage: stage });
    }

    setHeightBulk(hb) {
        this.setState({height_bulk: hb});
    }

    setUseDistrict(use_district) {
        this.setState({use_district: use_district});
    }

    setSpecialUseDistricts(special_use_districts) {
        this.setState({special_use_districts: special_use_districts});
    }

    setFormattedAddress(formatted_address) {
        this.setState({formatted_address: formatted_address});
    }

    setAllCodeSections(all_code_sections) {
        this.setState({all_code_sections: all_code_sections});
    }

    render() {
        const {current_stage} = this.state;

        return(
            <div class="planningCodeSearchEnvelope mt-5">
                <div class="container-xxl planningCodeSearchPage">
                    {
                        current_stage === Stages.QUESTIONS && 
                        <PlanningProjectDetails 
                            setStage={this.setStage}
                            setHeightBulk={this.setHeightBulk}
                            setUseDistrict={this.setUseDistrict}
                            setSpecialUseDistricts={this.setSpecialUseDistricts}
                            setFormattedAddress={this.setFormattedAddress}
                            setAllCodeSections={this.setAllCodeSections}
                        />
                    }
                    {
                        current_stage === Stages.RESULT && 
                            <AddressFlowComponent 
                                setStage={this.setStage}
                                height_bulk={this.state.height_bulk}
                                use_district={this.state.use_district}
                                special_use_districts={this.state.special_use_districts}
                                formatted_address={this.state.formatted_address}
                                all_code_sections={this.state.all_code_sections}
                            />
                    }
                    
                </div>
                
            </div>
        )
    }
}

function FullSectionModal(props) {
    const {active_section, setActiveSection} = props;
    
    if (!active_section) {
        return null;
    }

    return (   <Modal
                    show={active_section}
                    onHide={() => setActiveSection(null)}
                    size="lg"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="fullSectionText"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>SEC. {active_section.section_id} {active_section.section_name}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {
                            active_section.section_id === '124' ? 
                            <Section_124 />
                        :
                            <div class="container">
                                <div class="row">
                                    <div class="col" >
                                        <span dangerouslySetInnerHTML={{__html: active_section.formatted_section_text}} />
                                    </div>
                                </div>
                                {
                                    active_section.references.length > 0 && 
                                    <>
                                        <div class="row mt-3">
                                            <div class="col fw-bold">References</div>
                                        </div>
                                        <ul>
                                        {
                                            active_section.references.map(reference => {
                                                return <li className="text-decoration-underline" role="button">SEC. {reference.section_id} {reference.section_name}</li>
                                            })
                                        }
                                        </ul>
                                    </>
                                }
                            </div>
                        }
                    
                    </Modal.Body>
                </Modal> 
    )
}

function CodeSectionsComponent(props) {
    const [active_section, setActiveSection] = useState(null);
    // const [section_1, setSection1] = useState(null);

    return (<div>
                 <FullSectionModal
                    active_section={active_section}
                    setActiveSection={setActiveSection}
                />
           
            { props.code_sections && !props.show_documents &&
                <div class="codeSectionList mt-4">
                    {
                        props.code_sections.map(section => {
                            return  <div class="mb-4 p-2 border rounded">
                                        <div class="row">
                                            <div class="col fw-semibold">
                                                <i class="fa-regular fa-file-lines fa-lg"/>&nbsp;&nbsp;SEC. {section.section_id} {section.section_name}
                                            </div>
                                        </div>       
                                        <div class="row mt-1 p-2 ms-3">
                                            { section.section_id === '123' ?
                                                <Section_123_summary 
                                                    setShowDefinition={props.setShowDefinition}
                                                />
                                                :
                                                <span dangerouslySetInnerHTML={{__html: section.summary}} />
                                            }
                                        </div>
                                        <div class="row mt-1">
                                            <div class="col text-decoration-underline"><a role="button" onClick={() => setActiveSection(section)}>Read full section</a></div>
                                            <div class="col text-decoration-underline text-end">
                                                <Button variant="secondary" size="sm" onClick={() => props.addToReadingList("SEC. " + section.section_id + " " + section.section_name)}><i class="fa-solid fa-plus"></i></Button>
                                            </div>
                                        </div>
                                    </div>
                        })
                    }
                </div>
            }
            { props.show_documents && props.formatted_address &&
                <div class="codeSectionList">
                    <div class="row mt-4">
                        <div class="col text-secondary fs-5 text-center mb-2">Zoning Administrator letters of determination</div>
                    </div>
                    <div class="mb-4 p-2 border rounded">
                        <div class="row mb-1"><div class="col"><span class="fw-semibold">Topics: </span>Central SoMa Special Use District, Gym/fitness</div></div>
                        <div class="row mb-1"><div class="col"><span class="fw-semibold">Address: </span>635 Brannan Street</div></div>
                        <div class="row mb-1"><div class="col fw-semibold">Documents</div></div>
                        <div class="row mb-1 ms-2">
                            <div class="col">- <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/ZAD+Request+Letter+-+635+Brannan+Street+-+2023-009722ZAD.pdf" target="_blank" class="text-decoration-none  text-black">Owner/representative inquiry letter <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div>
                        </div>
                        <div class="row mb-1 ms-2">
                            <div class="col">- <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/LoD+Response+Letter+-+635+Brannan+Street+-+2023-009722ZAD.pdf" target="_blank" class="text-decoration-none  text-black">Zoning Administrator letter of determination <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col text-secondary fs-5 text-center mb-2">Planning bulletins</div>
                    </div>
                    <div class="mb-4 p-2 border rounded">
                        <div class="row mb-1"><div class="col fw-semibold "><i class="fa-regular fa-file-lines fa-lg"/> <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/ZAB_08_POPOS.pdf" target="_blank" class="text-decoration-none  text-black">Privately-Owned Public Open Spaces
                        Informational Plaque&nbsp;<i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></div>
                        <div class="row mb-1 ms-2">
                            <div class="col">This ZA Bulletin outlines Section 138 (i) (A) of the Planning Code regulate the content of -Owned Public Open Spaces (POPOS) Signage. The San Francisco Planning Department has adopted and shall implement this Code section based on the following standards.</div>
                        </div>
                    </div>
                </div>
            }
            { props.show_ordinances && props.formatted_address &&
                <div class="codeSectionList">
                    <div class="row mt-4">
                        <div class="col text-secondary fs-5 text-center mb-2">Relevant ordinances not yet effective</div>
                    </div>
                    
                    <div class="mb-4 p-2 border rounded">
                        <div class="row mb-1"><div class="col"><span class="fw-semibold">Title: </span>Planning Code - Exceptions and Extensions for Existing Uses</div></div>
                        <div class="row mb-1"><div class="col"><span class="fw-semibold">Ord #: </span>0068-24</div></div>
                        <div class="row mb-1"><div class="col"><span class="fw-semibold">Sections: </span>190, 191, 205.2, 242, 415A.2, 607.1, 838</div></div>
                        <div class="row mb-1"><div class="col"><span class="fw-semibold">Effective Date: </span>11/15/2024</div></div>
                        <div class="row mb-1"><div class="col fw-semibold">Documents</div></div>
                        <div class="row mb-1 ms-2">
                            <div class="col">- <a href="https://sfbos.org/sites/default/files/o0201-23.pdf" target="_blank" class="text-decoration-none  text-black">Ordinance 0068-24 <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div>
                        </div>
                    </div>
                    <div class="row mt-4 ms-1 me-1 border rounded p-2">
                        <div class="col text-secondary text-center fw-bold">We will notify you via email if any new relevant ordinances are enacted.</div>
                    </div>
                </div>
            }
    </div>)
}

function AddressFlowComponent(props) {

    const {height_bulk, use_district, special_use_districts, formatted_address, all_code_sections} = props;

    const [active_code_sections, setActiveCodeSections] = useState(null);

    const [show_code_section_component, setShowCodeSectionComponent] = useState(false);
    const [show_search_section_component, setShowSearchSectionComponent] = useState(false);
    const [active_button_map, setActiveButtonMap] = useState({});
    const [show_documents, setShowDocuments] = useState(false);
    const [show_ordinances, setShowOrdinances] = useState(false);
    const [reading_list, setReadingList] = useState([]);
    const [show_definition, setShowDefinition] = useState(false);

    const addToReadingList = (value) => {
        setReadingList(prevState => [
            ...prevState,
            value,
            ]);
    }

    const code_section_button_click = (section_name) => {
        setShowDefinition(false);
        setShowCodeSectionComponent(true);
        setShowSearchSectionComponent(false);
        setActiveCodeSections(all_code_sections[section_name]);
        setShowDocuments(false);
        setShowOrdinances(false);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            [section_name]: true,
        }));
    }

    const documents_button_click = () => {
        setShowDefinition(false);
        setShowCodeSectionComponent(true);
        setShowSearchSectionComponent(false);
        setShowDocuments(true);
        setShowOrdinances(false);
        setActiveCodeSections(null);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            'documents': true,
        }));
    }

    const question_click = () => {
        setShowCodeSectionComponent(false);
        setShowSearchSectionComponent(true);
        setShowDocuments(false);
        setShowOrdinances(false);
        setActiveCodeSections(null);
        setShowDefinition(false);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            'question': true,
        }));
    }

    const ordinances_button_click = () =>
    {
        setShowCodeSectionComponent(true);
        setShowSearchSectionComponent(false);
        setShowDocuments(false);
        setShowOrdinances(true);
        setActiveCodeSections(null);
        setShowDefinition(false);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            'ordinances': true,
        }));
    }

    const button_variant = (section_name) => {
        if (active_button_map[section_name]) {
            return 'secondary';
        }
        else {
            return 'outline-secondary';
        }
    }

    const large_button_variant = (section_name) => {
        if (active_button_map[section_name]) {
            return 'secondary';
        }
        else {
            return 'outline-secondary';
        }
    }

    return (
        <div class="container-xxl searchComponent">
            <div class="row">
                <div class="col-3 codeSectionsList">
                    <SFPlanningCode />
                </div>
                <div class="col-9">
                    { formatted_address &&
                        <div class="row">
                            <div class="col-9">
                                <Card className="mt-4">
                                    <Card.Body>
                                        <>
                                            <div class="row mt-2">
                                                <div class="col fw-semibold fs-6"><i class="fa-solid fa-house"></i>&nbsp;&nbsp;{formatted_address}</div>
                                            </div>    
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <hr></hr>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-5">
                                                    <div class="row">
                                                        <div class="col-12 fw-semibold text-success">Use district zone</div>
                                                        <div class="col-12">{use_district}</div>
                                                    </div>   
                                                    <div class="row mt-2">
                                                        <div class="col-12 fw-semibold text-success">Height and bulk district</div>
                                                        <div class="col-12">{height_bulk}</div>
                                                    </div>   
                                                </div>
                                                <div class="col-7">
                                                    <div class="row">
                                                        <div class="col-12  fw-semibold text-success">Special districts</div>
                                                        <div class="col-12">
                                                            <ul>
                                                                {
                                                                    special_use_districts.map(special_district => {
                                                                        return <li> {special_district}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <hr></hr>
                                                </div>
                                            </div>
                                            <div class="row mt-2 topicSection">
                                                <div class="container">
                                                    <div class="row mb-3">
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('far')} onClick={()=>code_section_button_click('far')}>Floor area ratio</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('setback')} onClick={()=>code_section_button_click('setback')} >Front setback</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('height')} onClick={()=>code_section_button_click('height')}>Height/bulk</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('rear_yard')} onClick={()=>code_section_button_click('rear_yard')}>Rear yard</Button>
                                                            </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-3 text-center">
                                                        <Button className="topicButton" variant={button_variant('parking')} onClick={()=>code_section_button_click('parking')}>Parking</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('street_scape')} onClick={()=>code_section_button_click('street_scape')}>Streetscape</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('frontage')} onClick={()=>code_section_button_click('frontage')}>Frontage</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('open_space')} onClick={()=>code_section_button_click('open_space')}>Open space</Button>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-3 text-center">
                                                        <Button className="topicButton" variant={button_variant('sun')} onClick={()=>code_section_button_click('sun')}>Sun/shadows</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('other')} onClick={()=>code_section_button_click('other')}>Other</Button>
                                                        </div>
                                                        <div class="col-6 text-center">
                                                        <Button className="searchButton" variant={large_button_variant('ordinances')} onClick={ ordinances_button_click }>New ordinances <i class="fa-solid fa-triangle-exclamation fa-lg" style={{color: "#fcc107" }} /></Button>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div class="row">
                                                        <div class="col-6 text-center">
                                                            <Button className="searchButton" variant={large_button_variant('documents')} onClick={ documents_button_click }>Relevant documents</Button>
                                                        </div>    
                                                        <div class="col-6 text-center">
                                                            <Button className="searchButton" variant={large_button_variant('question')} onClick={ question_click }>Ask a question</Button>
                                                        </div>    
                                                    </div>    
                                                </div>
                                            </div>
                                        </>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-3 readingList">
                                {
                                    reading_list.length > 0 && 
                                    <Card className="mt-4">
                                        <Card.Body>
                                            <div class="row  mb-2">
                                                <div class="col-auto fw-bold">Research list </div>
                                                <div class="col text-end"><a role="button" href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/san_francisco-ca-2+(3).pdf" target="_blank"><i class="fa-solid fa-download"></i></a></div>
                                            </div>
                                            {reading_list.map(section => { 
                                                return  <>  
                                                            <div class="row mt-1"><div class="col"><hr></hr></div></div>
                                                            <div class="row">
                                                                <div class="col text-truncate" role="button"><small>{section}</small></div>
                                                            </div>
                                                        </>
                                                })}
                                        </Card.Body>
                                    </Card>
                                }
                            </div>                                               
                        </div>
                    }
                    {
                        show_code_section_component && 
                        <div class="row">
                            <div class="col-9">
                                <CodeSectionsComponent 
                                    code_sections={active_code_sections}
                                    formatted_address={formatted_address}
                                    show_documents={show_documents}
                                    addToReadingList={addToReadingList}
                                    show_ordinances={show_ordinances}
                                    setShowDefinition={setShowDefinition}
                                />
                            </div>
                            <div class="col-3 readingList">
                                {
                                    show_definition && 
                                        <Card className="mt-4">
                                            <Card.Body>
                                                <div class="row"><div class="col"><strong>Floor Area Ratio.</strong> The ratio of the Gross Floor Area of 
                                                all the buildings on a lot to the area of the lot. In cases in which portions of the gross floor area of 
                                                a building project horizontally beyond the lot lines, all such projecting gross floor area shall also be 
                                                included in determining the floor area ratio.
                                                </div></div>
                                            </Card.Body>
                                        </Card>
                                }
                            </div>           
                        </div>
                    }
                    { 
                        show_search_section_component &&
                        <div class="row">
                            <div class="col-9">
                                <SearchComponent />
                            </div>
                            <div class="col-3 ">
                            </div>       
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

class AddressSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchButtonEnabled: false,
        }

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.resetAddressField = this.resetAddressField.bind(this);
    }
      
    addressChanged(e) {
        this.setState({
            searchButtonEnabled: false,
            address_field: e.target.value,
        });
    }

    resetAddressField() {
        this.autocompleteInput.current.value = "";
        this.setState({
            searchButtonEnabled: false,
        });
    }

    componentDidMount() {

        const center = { lat: 37.75953994968017, lng: -122.44697911726526 };

        // Create a bounding box with sides ~10km away from the center point
        const defaultBounds = {
            north: center.lat + 0.07,
            south: center.lat - 0.07,
            east: center.lng + 0.07,
            west: center.lng - 0.07,
        };
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
            {"types": ["geocode"], "fields": ["address_components", "formatted_address", "name", "geometry"], bounds: defaultBounds, strictBounds: true });

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        let streetNumber = '';
        let streetName = ''
        let city = '';
        let state = '';
        let zipCode = '';

        let i = 0;
        let lat = place.geometry.location.lat();
        let long = place.geometry.location.lng();

        for(; i < place.address_components.length; i++) {
            let component = place.address_components[i];
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            else if (component.types.includes("route")) {
                streetName = component.long_name;
            }
            else if (component.types.includes("locality")) {
                city = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
            }
            else if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
            }
        }

        this.props.setAddress({
            street_number: streetNumber,
            street_name: streetName,
            city: city,
            state: state,
            zip_code: zipCode,
            formatted_address: place.formatted_address,
            lat: lat,
            long: long,
        });

        this.setState({
            searchButtonEnabled: true,
        })
    }

    render() {
        const { searchButtonClick, searching_address } = this.props;
        
        return(
            <div class="container addressContainer">
                <div class="row gy-3">
                    <div class="col-12 col-md-9">
                        <div class="input-group">
                            <input type="text" class="form-control" placeHolder="address" ref={this.autocompleteInput}  id="autocomplete" onChange={ (e) => this.addressChanged(e) }/>
                            <Button className="bg-transparent text-dark clearButton" onClick={this.resetAddressField}>
                                <i class="fa fa-times"/>
                            </Button>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        {searching_address? 
                            <Button variant="primary" disabled className="addressButton">
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                &nbsp;&nbsp;Searching...
                            </Button>
                        :
                            <Button variant="primary" className="addressButton" disabled={ !this.state.searchButtonEnabled } onClick={ searchButtonClick }>
                                Search
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function SearchComponent (props) {
    const [text_box_text, setTextBoxText] = useState('');
    const [result, setResult] = useState('');
    const [waiting_for_result, setWaitingForResult] = useState(false);

    const search = () => {
        setResult('');
        setWaitingForResult(true);

        const interval = setTimeout(() => {
            setWaitingForResult(false);
            
            if (text_box_text.includes('slope')){
                setResult(answer_2);
            } 
            else if (text_box_text.includes('elevator')){
                setResult(answer_1);
            }
            else if (text_box_text.includes('gym')){
                setResult(answer_3);
            }
        }, 2000);
    }

    return (
        <div class="container searchComponent  mt-4">
            <div class="row">
                <div class="input-group">
                    <input type="search" 
                        placeHolder="Type your question"
                        class="form-control"
                        name="search" 
                        id="search"
                        value={text_box_text}
                        onChange={ (e) => setTextBoxText(e.target.value) }
                    />
                    {   waiting_for_result ?
                            <Button variant="warning" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Searching...
                            </Button>
                        :
                        <Button variant="warning" onClick={search}><i class="fa-regular fa-paper-plane"></i></Button>    
                    }
                    
                </div>
            </div>
            
            <div class="row mt-4 p-3">
                {
                    // result && <div class="col"><span dangerouslySetInnerHTML={{__html: result}} /></div>
                    result && <div class="col">{result}</div>
                }
            </div>
        </div>
    )
}

function PlanningProjectDetails(props){

    const { setHeightBulk, setUseDistrict, setSpecialUseDistricts, setFormattedAddress, setAllCodeSections, setStage} = props;
    const [current_page, setCurrentPage] = useState('start');
    const [address, setAddress] = useState(null);
    const [searching_address, setSearchingAddress] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const submit = () => {
        
        setSubmitting(true);
        setTimeout(() =>      
            {setSubmitting(false); setStage(Stages.RESULT) },
            2000
        );
    }
    const searchButtonClick = () => {
        setSearchingAddress(true);

        let request = {
            "request_type": 'property_info',
            "request_address": address,
        }

        generic_post_api(request, PLANNING_PROPERTY_INFO)
        .then(
            result => {
                return result.data.json();
               
            })
        .then(data => {
            setFormattedAddress(data.formatted_address);
            setHeightBulk(data.height_bulk);
            setUseDistrict(data.use_district);
            setSpecialUseDistricts(data.special_use_districts);

            heightButtonClick(data.formatted_address, data.height_bulk, data.use_district, data.special_use_districts);
        })
        .catch(error => {
            setSearchingAddress(false);
        });
    }

    const heightButtonClick = (address, height, use, special) => {

        let request = {
            "request_type": 'height_info',
            'height_bulk': height,
            'use_district': use,
            'special_use_districts': special,
            'formatted_address': address,
        }

        generic_post_api(request, PLANNING_PROPERTY_INFO)
        .then(
            result => {
                return result.data.json();
               
            })
        .then(data => {
            setAllCodeSections(data);
            setSearchingAddress(false);
            setCurrentPage('code_date');
        })
        .catch(error => {
        });
    }

    return(
        <div class="planningProjectDetails container">
             <div class="row">
                <div class="col text-center display-6">Property &amp; project details</div>
            </div>
            { current_page === 'start' &&
                <div class="row mt-4">
                    <div class="col">
                        <Card>
                            <Card.Body>
                                <Card.Text className="ms-3 fw-semibold text-center">
                                    <p>Start with the project name</p>
                                </Card.Text>
                                <div class="container">
                                    <div class="row">
                                        <div class="col"><input type="text" class="form-control" placeHolder="project name"/></div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-end">
                                            <Button variant="primary" onClick={()=> setCurrentPage('address')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>    
                    </div>
                </div>
            }
            {
                current_page === 'address' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Property's address</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row ">
                                <AddressSection 
                                    setAddress={ setAddress }
                                    searchButtonClick={ searchButtonClick }
                                    searching_address={ searching_address }
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                current_page === 'code_date' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Code version date</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row">
                                    <div class="datePickerInput col text-center">
                                        <DatePicker 
                                            allowSameDay={true}
                                            inline
                                            maxDate={new Date()}
                                            showDisabledMonthNavigation
                                            className="datePicker text-center"
                                            placeholderText="Select date"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="primary" onClick={()=> setCurrentPage('project')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }

            {
                current_page === 'project' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Select all that apply to your project</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="horizontal">
                                                    <ToggleButton className="text-nowrap projectButton" id="horizontal-radio-1" value={1} variant="outline-success"
                                                        > New construction  
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="vertical">
                                                    <ToggleButton className="text-nowrap projectButton" id="vertical-radio-1" value={1} variant="outline-success"
                                                        > Addition  
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="ADU">
                                                    <ToggleButton className="text-nowrap projectButton" id="ADU-radio-1" value={1} variant="outline-success"
                                                        > Change of use  
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="garage">
                                                    <ToggleButton className="text-nowrap projectButton" id="garage-radio-1" value={1} variant="outline-success"
                                                        > Public garage
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('lot')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {/* {
                current_page === 'height' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Height &amp; bulk</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col-6">Current property height</div>
                                    <div class="col-2"><input type="text" class="form-control" /></div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Proposed property height</div>
                                    <div class="col-2"><input type="text" class="form-control"/></div>
                                </div>
                                
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('lot')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            } */}
            {
                current_page === 'lot' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Current</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col-6">Is the property on a narrow street</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="narrow">
                                            <ToggleButton
                                                id="tbg-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="tbg-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="tbg-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Is the lot vacant</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="vacant">
                                            <ToggleButton
                                                id="vacant-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="vacant-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="vacant-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Is this a corner lot</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="corner">
                                            <ToggleButton
                                                id="corner-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="corner-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="corner-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Is it currently a historical building</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="habitable">
                                            <ToggleButton
                                                id="habitable-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="habitable-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="habitable-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Proposed use</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="Proposeduse">
                                            <ToggleButton
                                                id="Proposeduse-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Residential  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Proposeduse-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Commercial    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Proposeduse-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Mix-use 
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('frontage')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                current_page === 'frontage' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Proposed</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col-6">Adding efficiency dwelling units</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="habitable">
                                            <ToggleButton
                                                id="habitable-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="habitable-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="habitable-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Demolishing, removing, combining existing habitable space</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="Repaving">
                                            <ToggleButton
                                                id="Repaving-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Repaving-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Repaving-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Adding any of the following</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="checkbox" name="awning">
                                            <ToggleButton
                                                id="awning-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Awning  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="awning-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Canopy    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="awning-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Marquee
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 text-end">
                                        <Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {
                                            !submitting ? 
                                                <Button variant="primary" onClick={submit}>Submit&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button>
                                            :
                                                <Button variant="primary" onClick={submit}>Submitting&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></Button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
        </div>
    )
}

function Section_124(props) {
    const [visible_parts, setVisibleParts] = useState({});

    const setVisible = (part, value) => {
        setVisibleParts(prevState => ({
            ...prevState,
            [part]: value,
        }));
    }

    return(
        <div class="container">
            <div class="row mb-3"><div class="col"><a role="button" className="text-decoration-underline" onClick={()=> {setVisible('section_1', true); setVisible('section_2', true); setVisible('section_3', true)}}>View entire text</a></div></div>
            <div class="row">
                <div class="sub-section-container sectionSummary col">
                    <ol type="a">
                        <li>
                            Except as provided in subsections (b), (c), (d), (e), and (l) of this Section 124, the basic Floor Area
                            Ratio limits specified in the Zoning Control Table for the district in which the lot is located, or in Table
                            124 below, shall apply to each building or development in the districts indicated.
                            <table>
                                <tr>
                                    <th>District</th>
                                    <th>Basic Floor Area Ratio Limit</th>
                                </tr>
                                {
                                    ! visible_parts.section_1 && 
                                    <tr>
                                        <td><strong>Show all districts&nbsp;</strong><a role="button" onClick={() => setVisible('section_1', true)}><i class="fa-regular fa-square-caret-down fa-lg"></i></a></td>
                                    </tr>
                                }
                                {
                                    visible_parts.section_1 && 
                                    <>
                                        <tr  className="bg-warning">
                                        <td>RED, RED-MX</td>
                                            <td>1.0 to 1</td>
                                        </tr>
                                        <tr className="bg-warning">
                                            <td>Pacific</td>
                                            <td>1.5 to 1</td>
                                        </tr>
                                        <tr className="bg-warning">
                                            <td>RH-1(D), RH-1, RH-1(S), RH-2, RH-3</td>
                                            <td>1.8 to 1</td>
                                        </tr>
                                        <tr className="bg-warning">
                                            <td>RM-1, RM-2</td>
                                            <td>1.8 to 1</td>
                                        </tr>
                                        <tr className="bg-warning">
                                            <td>RTO, RTO-M</td>
                                            <td>1.8 to 1</td>
                                        </tr>
                                        <tr className="bg-warning">
                                            <td>SPD, NC-1, NCT-1, NC-S</td>
                                            <td>1.8 to 1</td>
                                        </tr>
                                        <tr className="bg-warning">
                                            <td>NC-2, NCT-2, RCD</td>
                                            <td>2.5 to 1</td>
                                        </tr>
                                        <tr className="bg-warning">
                                            <td>Broadway</td>
                                            <td>2.5 to 1</td>
                                        </tr>
                                        <tr className="bg-warning">
                                            <td>C-3-O(SD), C-3-R, C-3-G</td>
                                            <td>6.0 to 1</td>
                                        </tr>
                                        <tr className="bg-warning">
                                            <td>MUG, MUO, MUR, UMU, WMUG, WMUO</td>
                                            <td>7.5 to 1</td>
                                        </tr>
                                        <a role="button" onClick={() => setVisible('section_1', false)}>&nbsp;<i class="fa-regular fa-square-caret-up fa-lg"></i></a>
                                    </>
                                }
                                <tr>
                                    <td>C-3-O</td>
                                    <td>9.0 to 1</td>
                                </tr>
                            </table>
                        </li>
                        {
                            ! visible_parts.section_2 &&
                            <li>
                                <strong>Subsections (b) - (g)</strong>&nbsp;<a role="button" onClick={() => setVisible('section_2', true)}><i class="fa-regular fa-square-caret-down fa-lg"></i></a>
                            </li> 
                        }
                        {
                            visible_parts.section_2 &&
                            <>
                                <li><span className="bg-warning">In R, RC, NC, and Mixed Use Districts, floor area ratio limits shall not apply to dwellings or to other
                                    residential uses. In Chinatown Mixed Use Districts, the above floor area ratio limits shall not apply to
                                    institutions, and mezzanine commercial space shall not be calculated as part of the floor area ratio.</span>
                                </li>
                                <li><span className="bg-warning">In a C-2 District the basic floor area ratio limit shall be 4.8 to 1 for a lot which is nearer to an RM-4 or
                                    RC-4 District than to any other R District, and 10.0 to 1 for a lot which is nearer to a C-3 District than
                                    to any R
                                    District. The distance to the nearest R District or C-3 District shall be measured from the midpoint of the
                                    front
                                    line, or from a point directly across the street therefrom, whichever gives the greatest ratio.</span>
                                </li>
                                <li><span className="bg-warning">In the Van Ness Special Use District, as described in Section 243 of this Code, the basic floor area ratio
                                    limit
                                    shall be 7.0 to 1 where the height limit is 130 feet and at the hospital site within the Van Ness Medical
                                    Use
                                    Subdistrict, and 4.8 to 1 where the height limit is 80 feet. Within the Van Ness Medical Use Subdistrict,
                                    the basic
                                    floor area ratio limit shall be 7.5 to 1 for a medical office building, subject to Conditional Use
                                    Authorization for
                                    a hospital, medical center or other medical institution.</span>
                                </li>
                                <li><span className="bg-warning">In the Waterfront Special Use Districts, as described in Sections 240 through 240.3 of this Code, the basic
                                    floor area ratio limit in any C District shall be 5.0 to 1.</span>
                                </li>
                                <li><span className="bg-warning">For buildings in C-3-G and C-3-S Districts, other than those designated as Significant or Contributory
                                    pursuant
                                    to Article 11 of this Code, additional square footage above that permitted by the base floor area ratio
                                    limits set
                                    forth above may be approved for construction of dwellings on the site of the building affordable for the
                                    Life of the
                                    Project, as defined in Section 401, to households whose incomes are within 150 percent of AMI, as defined in
                                    Section
                                    401, for ownership units and up to 120% of AMI for rental units, in accordance with the conditional use
                                    procedures
                                    and criteria as provided in Section 303 of this Code. For buildings in the C-3-G District designated as
                                    Significant
                                    or Contributory pursuant to Article 11 of this Code, additional square footage above that permitted by the
                                    base
                                    floor area ratio limits set forth above up to the Gross Floor Area of the existing building may be approved,
                                    in
                                    accordance with the conditional use procedures and criteria as provided in Section 303 of this Code, where:
                                    (1) TDRs
                                    (as defined by Section 128(a)(5)) were transferred from the lot containing the Significant or Contributory
                                    building
                                    prior to the effective date of the amendment to Section 124(f) adding this paragraph when the floor area
                                    transferred
                                    was occupied by a non profit corporation or institution meeting the requirements for exclusion from Gross
                                    Floor Area
                                    calculation; (2) the additional square footage includes only the amount necessary to accommodate dwelling
                                    units
                                    and/or group housing units that are affordable for the Life of the Project to households whose incomes are
                                    within 60
                                    percent of AMI as defined herein together with any social, educational, and health service space accessory
                                    to such
                                    units; and (3) the proposed change in use to dwelling units and accessory space and any construction
                                    associated
                                    therewith, if it requires any alternation to the exterior or other character defining features of the
                                    Significant or
                                    Contributory Building, is undertaken pursuant to the duly approved Permit to Alter, pursuant to Section
                                    1110,
                                    provided, however, that the procedures otherwise required for a Major Alteration as set forth in Sections
                                    1111.4 and
                                    1111.5 and shall be deemed applicable to any such Permit to Alter.</span>
                                    <ol type="1">
                                        <li><span className="bg-warning">Any dwelling approved for construction under this provision shall be deemed a "Designated Unit" as
                                            defined
                                            below. Prior to the issuance by the Director of the Department of Building Inspection ("Director of
                                            Building
                                            Inspection") of a First Construction Document to construct any Designated Unit subject to this
                                            Section, the permit
                                            applicant shall notify the Director of Planning and the Director of MOHCD in writing whether the
                                            Designated Unit
                                            will be an owned or rental unit as defined in Section 401 of this Code.</span>
                                        </li>
                                        <li><span className="bg-warning">Unless specifically stated in this Section 124(f), each designated unit shall be subject to the
                                            provisions of
                                            Section 415 of this Code. For purposes of this Subsection and the application of Section 415 of this
                                            Code to
                                            Designated Units constructed pursuant to this Subsection, the definitions set forth in Section 401
                                            of this Code
                                            shall apply.</span>
                                        </li>
                                        <li><span className="bg-warning">Except as specifically specified herein, Designated Units shall meet all of the procedures, pricing
                                            methodology,
                                            monitoring obligations and other requirements of the Inclusionary Housing Procedures Manual and
                                            either:</span>
                                            <ol type="A">

                                                <li><span className="bg-warning">Be used to satisfy the requirements of the Inclusionary Affordable Housing Program, Section
                                                    415 et seq., and
                                                    meet all of the requirements of that Program, including the income limits specified therein;
                                                    or</span>
                                                </li>
                                                <li><span className="bg-warning">Meet the requirements of this subsection (f), including the income limits specified, and be
                                                    family sized,
                                                    meaning that each Designated Unit contains at least 2 or 3 bedrooms. In the event that the
                                                    Designated Unit is not
                                                    also an On- or Off-site Unit under Section 415, Designated Units shall not be used to
                                                    determine the required unit
                                                    size mix for purposes of the Inclusionary Affordable Housing Program.</span>
                                                </li>

                                            </ol>
                                        </li>
                                        <li><span className="bg-warning">MOHCD shall update its Procedures Manual if necessary to include any specific provisions related to
                                            Designated
                                            Units.</span>
                                        </li>
                                        <li><span className="bg-warning">Affordable unit gross square footage which is exempted per this section for FAR shall not be
                                            exempted for impact
                                            fees that are levied on a gross square foot or FAR basis.</span>
                                        </li>
                                    </ol>
                                </li>
                                <li><span className="bg-warning">The allowable Gross Floor Area on a lot which is the site of an unlawfully demolished building that is
                                    governed by the provisions of Article 11 shall be the Gross Floor Area of the demolished building for the period of
                                    time set forth in, and in accordance with the provisions of, Section 1116 of this Code, but not to exceed the basic
                                    floor
                                    area permitted by this Section.</span>
                                </li>
                                <a role="button" onClick={() => setVisible('section_2', false)}>&nbsp;<i class="fa-regular fa-square-caret-up fa-lg"></i></a>
                        </>
                        }
                        <li>In calculating the permitted floor area of a new structure in a C-3 District, the lot on which an existing
                            structure is located may not be included unless the existing structure and the new structure are made part
                            of a single development complex, the existing structure is or is made architecturally compatible with the new
                            structure, and, if the existing structure is in a Conservation District, the existing structure meets or is made to
                            meet the standards of Section 1109(c), and the existing structure meets or is reinforced to meet the standards for
                            seismic loads and forces of the Building Code. Determinations under this Paragraph shall be made in accordance with
                            the provisions of Section 309.
                        </li>
                        {
                            ! visible_parts.section_3 &&
                            <li>
                                <strong>Subsections (i) - (l)</strong>&nbsp;<a role="button" onClick={() => setVisible('section_3', true)}><i class="fa-regular fa-square-caret-down fa-lg"></i></a>
                            </li> 
                        }
                        {
                            visible_parts.section_3 &&
                            <>
                                <li><span className="bg-warning">In calculating allowable Gross Floor Area on a preservation lot from which any TDRs have been transferred
                                pursuant to Section 128, the amount allowed herein shall be decreased by the amount of gross floor area
                                transferred.</span>
                                </li>
                                <li><span className="bg-warning">For buildings in C-3-G and C-3-S Districts that are not designated as Significant or Contributory pursuant
                                    to Article 11 of this Code, additional square footage above that permitted by the base floor area ratio limits
                                    set forth above may be approved for construction of a project, or portion thereof, that constitutes a Student
                                    Housing project, as defined in Section 102 of this Code. Such approval shall be subject to the conditional use
                                    procedures and criteria in Section 303 of this Code.</span>
                                </li>
                                <li><span className="bg-warning">In the Cesar Chavez/Valencia Streets Medical Use Special Use District, as described in Section 249.68 of
                                    this Code, the basic floor area ratio limit shall be 2.6 to 1, subject to Conditional Use Authorization of a
                                    Hospital.</span>
                                </li>
                                <li><span className="bg-warning">For PDR-1-B, PDR-1-D, PDR-1-G and PDR-2 Districts see Table 210.3.</span></li>
                                <a role="button" onClick={() => setVisible('section_3', false)}>&nbsp;<i class="fa-regular fa-square-caret-up fa-lg"></i></a>
                            </>
                        }
                        
                    </ol>
                </div>
            </div>
        </div>
    )

}

function Section_123_summary(props){
    return  <div class="sectionSummary">
                <div class="sp">The maximum <span onClick={()=> props.setShowDefinition(true)} role="button">floor area ratio <i class="fa-regular fa-comment-dots" style={{color: '#d32727'}}></i></span> for any building or development shall be equal to the sum of the basic floor area ratio for the district, as set forth in Section 124, plus any premiums and floor area transfers which are applicable to such building or development.</div>

                <div class="sp">In the C-3 Districts, the amount of TDR that may be transferred to a development lot, as allowed by Section 128, is limited as follows:
                </div>
                <div class="sp">The gross floor area of a structure on a lot in the C-3-O District may not exceed a floor area ratio of <strong>18 to 1</strong>.</div>
            </div>
}

export default PlanningCodeSearch633;