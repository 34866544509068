/* global google */
// do not delete above line. Needed for google address to function

import React, { Component, useState, useRef, useEffect } from "react";
import { PLANNING_PROPERTY_INFO } from 'utils/constants';
import DatePicker from "react-datepicker"; // https://reactdatepicker.com/
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Spinner, Modal, ButtonGroup, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { generic_post_api } from "api/generic_api";


var get_yesterday_date = () => {
    var today = new Date();
    today.setDate(today.getDate() - 1);

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
}

const Stages = {
    QUESTIONS: 'questions',
    RESULT: 'result',
}

function Answer_1(props){
    return(    <Card>
                        <Card.Body>
                            
                            <div class="answerEnvelop">
                                <div class="mb-2">
                                    According to <strong>SEC. 260</strong>, certain features are exempt from height limitations, provided specific conditions are met.
                                </div>

                                <div class="fw-bold">
                                    Horizontal Area Limitation
                                </div>
                                <ul>
                                    <li>The total horizontal area of all exempt features must not exceed 30% of the roof's horizontal area.</li>
                                    <li>Within the first 10 feet of depth from the building's front wall, the sum of the horizontal areas of these
                                        features must not exceed 20% of the roof's horizontal area in that section.
                                    </li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Specific Exempt Features
                                </div>
                                <ul>
                                    <li>Includes elevator, stair, and mechanical penthouses, fire towers, skylights, and dormer windows.</li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Height Limitations for Exempt Features
                                </div>
                                <ul>
                                    <li>Exemption is limited to the top 16 feet of these features if the building height limit is 65 feet or less.
                                    </li>
                                    <li>If the building height limit is over 65 feet, the exemption applies to the top 20 feet of these features.
                                    </li>
                                    <li>For elevator penthouses, the exemption is limited to the top 16 feet, restricted to the footprint of the
                                        elevator shaft, regardless of the building's height limit.
                                    </li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Design Consistency
                                </div>
                                <ul>
                                    <li>Elevator penthouses in Residential Districts must comply with the "Residential Design Guidelines" as adopted
                                        by the Planning Commission.
                                    </li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Further Height Exemption
                                </div>
                                <ul>
                                    <li>The Zoning Administrator may grant additional height exemptions for elevator penthouses in buildings with
                                        height limits over 65 feet after a public hearing.
                                    </li>
                                    <li>This exemption is only permissible if necessary to comply with state or federal laws or regulations.</li>
                                    <li>Requests for height exemptions in Residential or Neighborhood Commercial Districts must follow the
                                        neighborhood notification requirements of Section 311 of the Code.
                                    </li>
                                </ul>
                                <div class="mt-2 fw-bold">Sources:</div>
                                <ul>
                                    <li><div role="button">SEC. 260: Height Limits: Measurement <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>
    )
}

function Answer_2(props){
    return(    <Card>
                        <Card.Body>
                            
                            <div class="answerEnvelop">
                                <div class="mb-2">
                                    According to <strong>SEC. 135</strong>, the slope of any usable open space, whether it is private or common, must not exceed five percent.
                                </div>

                                <div class="mt-2 fw-bold">Sources:</div>
                                <ul>
                                    <li><div role="button">SEC. 135: Usable open space for dwelling units and group housing <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>
    )
}

function Answer_3(props){
    return (     <Card>
                        <Card.Body>
                            
                            <div class="answerEnvelop">
                                <div class="mb-2">
                                <ul>
                                    <li>A gym is classified as a "Retail Sales and Service Use," which includes health clubs, fitness centers, and exercise facilities.</li>
                                    <li>Gym use is permitted as part of Retail Sales and Service, with a maximum of 90,000 Gross Square Feet allowed per lot.</li>
                                    <li>If the gym qualifies as "Formula Retail" (as per Planning Code Section 303.1), it requires a Conditional Use Authorization from the Planning Commission.</li>
                                    <li>The property must maintain an "active use" on the ground floor, and a gym satisfies this requirement.</li>
                                    <li>The property is within the Central SoMa Special Use District, which does not impose any specific restrictions on using the property as a gym.</li>
                                </ul>

                                </div>

                                <div class="mt-2 fw-bold">Sources:</div>
                                <ul>
                                    <li><div role="button">SEC. 102: Definitions <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                    <li><div role="button">SEC. 303.1: Formula retail uses <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                    <li><div role="button">ZA letter of determination (November 22, 2023) <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/LoD+Response+Letter+-+635+Brannan+Street+-+2023-009722ZAD.pdf" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></li>
                                </ul>
                            </div>
                        </Card.Body>
                        </Card>
    )
}

function Answer_4(props){
    const [show_modal, setShowModal] = useState(false);     
    
    return (
        <>
            <Modal
                show={show_modal}
                onHide={() => setShowModal(false)}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="fullSectionText"
            >
                <Modal.Header closeButton>
                    <Modal.Title>SEC. 607.2 Mixed Use Districts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Section_607_2 />
                </Modal.Body>
            </Modal>
            <Card>
                <Card.Body>
                    
                    <div class="answerEnvelop">
                        <div class="mb-2">
                            Section 607.2 regulates signs in Mixed Use Districts, with exceptions for Residential Enclave Districts and specific exempted 
                            signs. <strong>General advertising, roof, wind, and animated signs are prohibited.</strong> Identifying and business 
                            signs are allowed but must follow size, location, and illumination rules, including limits on window, wall, and projecting 
                            signs. 
                        </div>

                        <div class="mt-2 fw-bold">Sources:</div>
                        <ul>
                            <li><div role="button">SEC. 607.2: Mixed use districts <a onClick={() => setShowModal(true)}><i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></li>
                        </ul>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
    
class PlanningCodeSearch680 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_stage: Stages.QUESTIONS,
            height_bulk: '',
            use_district: '',
            special_use_districts: '',
            formatted_address: '',
            all_code_sections: null,
        };
        this.setStage = this.setStage.bind(this);

        this.setHeightBulk = this.setHeightBulk.bind(this);
        this.setUseDistrict = this.setUseDistrict.bind(this);
        this.setSpecialUseDistricts = this.setSpecialUseDistricts.bind(this);
        this.setFormattedAddress = this.setFormattedAddress.bind(this);
        this.setAllCodeSections = this.setAllCodeSections.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'planning_code_search'});
    }

    setStage(stage) {
        this.setState({ current_stage: stage });
    }

    setHeightBulk(hb) {
        this.setState({height_bulk: hb});
    }

    setUseDistrict(use_district) {
        this.setState({use_district: use_district});
    }

    setSpecialUseDistricts(special_use_districts) {
        this.setState({special_use_districts: special_use_districts});
    }

    setFormattedAddress(formatted_address) {
        this.setState({formatted_address: formatted_address});
    }

    setAllCodeSections(all_code_sections) {
        this.setState({all_code_sections: all_code_sections});
    }

    render() {
        const {current_stage} = this.state;

        return(
            <div class="planningCodeSearchEnvelope mt-5">
                <div class="container-xxl planningCodeSearchPage">
                    {
                        current_stage === Stages.QUESTIONS && 
                        <PlanningProjectDetails 
                            setStage={this.setStage}
                            setHeightBulk={this.setHeightBulk}
                            setUseDistrict={this.setUseDistrict}
                            setSpecialUseDistricts={this.setSpecialUseDistricts}
                            setFormattedAddress={this.setFormattedAddress}
                            setAllCodeSections={this.setAllCodeSections}
                        />
                    }
                    {
                        current_stage === Stages.RESULT && 
                            <AddressFlowComponent 
                                setStage={this.setStage}
                                height_bulk={this.state.height_bulk}
                                use_district={this.state.use_district}
                                special_use_districts={this.state.special_use_districts}
                                formatted_address={this.state.formatted_address}
                                all_code_sections={this.state.all_code_sections}
                            />
                    }
                    
                </div>
                
            </div>
        )
    }
}

function FullSectionModal(props) {
    const {active_section, setActiveSection} = props;
    
    if (!active_section) {
        return null;
    }

    return ( <Modal
                show={active_section}
                onHide={() => setActiveSection(null)}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="fullSectionText"
            >
                <Modal.Header closeButton>
                    <Modal.Title>SEC. {active_section.section_id} {active_section.section_name}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <div class="container">
                    <div class="row">
                        <div class="col" >
                            <span dangerouslySetInnerHTML={{__html: active_section.formatted_section_text}} />
                        </div>
                    </div>
                    {
                        active_section.references.length > 0 && 
                        <>
                            <div class="row mt-3">
                                <div class="col fw-bold">References</div>
                            </div>
                            <ul>
                            {
                                active_section.references.map(reference => {
                                    return <li className="text-decoration-underline" role="button">SEC. {reference.section_id} {reference.section_name}</li>
                                })
                            }
                            </ul>
                        </>
                    }
                </div>
                </Modal.Body>
            </Modal>)
}

function CodeSectionsComponent(props) {
    const [active_section, setActiveSection] = useState(null);

    return (<div>
                <FullSectionModal
                    active_section={active_section}
                    setActiveSection={setActiveSection}
                />
           
            { props.code_sections && !props.show_documents &&
                <div class="codeSectionList mt-4">
                    {
                        props.code_sections.map(section => {
                            return  <div class="mb-4 p-2 border rounded">
                                        {
                                            section.section_id === '102' && 
                                            <>
                                                <div class="row">
                                                    <div class="col fw-semibold">
                                                        <i class="fa-regular fa-file-lines fa-lg"/>&nbsp;&nbsp;Relevant definitions
                                                    </div>
                                                </div>       
                                                <div class="row mt-3 p-2 ms-3">
                                                    <div class="col">
                                                        <div class="row fw-bold"><div class="col">SEC. 102 Automotive service station&nbsp;<i class="fa-solid fa-arrow-up-right-from-square"></i></div></div>
                                                        <div class="row"><div class="col">A Retail Automotive Use that provides motor fuels and lubricating oils directly into motor vehicles and minor auto repairs (excluding engine repair, rebuilding, or installation of power train components, reconditioning of badly worn or damaged motor vehicles, collision service, or full body paint spraying) and services that remain incidental to the principal sale of motor fuel. Repairs shall be conducted within no more than three enclosed service bays in buildings having no openings, other than fixed windows or exits required by law, located within 40 feet of any R District. It may include other incidental services for automobiles including, but not limited to, accessory towing, if the number of towing vehicles does not exceed one, and all towed vehicles stored on the premises are limited to those vehicles that are to be repaired on the premises.</div></div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3 p-2 ms-3">
                                                    <div class="col">
                                                        <div class="row fw-bold"><div class="col">SEC. 102 Gas station&nbsp;<i class="fa-solid fa-arrow-up-right-from-square"></i></div></div>
                                                        <div class="row"><div class="col">A Retail Automotive Use that provides motor fuels, lubricating oils, air, and water directly into motor vehicles and without providing Automotive Repair services, and which also includes self-service operations that sell motor fuel only. This use is subject to the controls in Sections 202.2(b) and 187.1.</div></div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3 p-2 ms-3">
                                                    <div class="col">
                                                        <div class="row fw-bold"><div class="col">SEC. 102 Electric Vehicle Charging Location&nbsp;<i class="fa-solid fa-arrow-up-right-from-square"></i></div></div>
                                                        <div class="row"><div class="col">Automotive Use, Retail that provides electricity to electric motor vehicles through more than one Electric Vehicle Charging Stations on a retail basis to the general public as a primary use. Electric Vehicle Charging Locations may include ancillary services, including but not limited to restrooms, self-service vending, and limited retail amenities primarily for the benefit of customers charging their vehicles.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3 p-2 ms-3">
                                                    <div class="col">
                                                        <div class="row fw-bold"><div class="col">SEC. 890 Definitions for mixed-use districts&nbsp;<i class="fa-solid fa-arrow-up-right-from-square"></i></div></div>
                                                        <div class="row"><div class="col">This and the following Sections provide the definitions for Mixed Use Districts. In case of conflict between the following definitions and those set forth in Section 102, the following definitions shall prevail for Mixed Use Districts, unless the only definition or definitions referred to for a zoning category are ones in Section 102.</div></div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3 p-2 ms-3">
                                                    <div class="col">
                                                        <div class="row fw-bold"><div class="col">SEC. 890.18 Automotive service station&nbsp;<i class="fa-solid fa-arrow-up-right-from-square"></i></div></div>
                                                        <div class="row"><div class="col">A retail automotive service use which provides motor fuels and lubricating oils directly into motor vehicles and minor auto repairs (excluding engine repair, rebuilding, or installation of power train components, reconditioning of badly worn or damaged motor vehicles, collision service, or full body paint spraying) and services which remain incidental to the principal sale of motor fuel. Repairs shall be conducted within no more than three enclosed service bays in buildings having no openings, other than fixed windows or exits required by law, located within 50 feet of any R District. It may include other incidental services for automobiles including, but not limited to, accessory towing, if the number of towing vehicles does not exceed one, and all towed vehicles stored on the premises are limited to those vehicles which are to be repaired on the premises.</div></div>
                                                    </div>
                                                </div>
                                               
                                                <div class="row mt-3 p-2 ms-3">
                                                    <div class="col">
                                                        <div class="row fw-bold"><div class="col">SEC. 890.14 Automotive gas station&nbsp;<i class="fa-solid fa-arrow-up-right-from-square"></i></div></div>
                                                        <div class="row"><div class="col">A retail automotive service use which provides motor fuels, lubricating oils, air, and water directly into motor vehicles and without providing automotive repair services, and which also includes self-service operations which sell motor fuel only.</div></div>
                                                    </div>
                                                </div>
                                              
                                            </>
                                        }
                                        {
                                            section.section_id !== '102' &&
                                            <>
                                                <div class="row">
                                                    <div class="col fw-semibold">
                                                        <i class="fa-regular fa-file-lines fa-lg"/>&nbsp;&nbsp;SEC. {section.section_id} {section.section_name}
                                                    </div>
                                                </div>       
                                                <div class="row mt-1 p-2 ms-3"><span dangerouslySetInnerHTML={{__html: section.summary}} /></div>
                                                <div class="row mt-1">
                                                    <div class="col text-decoration-underline"><a role="button" onClick={() => setActiveSection(section)}>Read full section</a></div>
                                                    <div class="col text-decoration-underline text-end">
                                                        <Button variant="secondary" size="sm" onClick={() => props.addToReadingList("SEC. " + section.section_id + " " + section.section_name)}><i class="fa-solid fa-plus"></i></Button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                        })
                    }
                </div>
            }
            
    </div>)
}

function AddressFlowComponent(props) {

    const {height_bulk, use_district, special_use_districts, formatted_address, all_code_sections} = props;

    const [active_code_sections, setActiveCodeSections] = useState(null);

    const [show_code_section_component, setShowCodeSectionComponent] = useState(false);
    const [show_search_section_component, setShowSearchSectionComponent] = useState(false);
    const [active_button_map, setActiveButtonMap] = useState({});
    const [show_documents, setShowDocuments] = useState(false);
    const [reading_list, setReadingList] = useState([]);
    const [show_definition, setShowDefinition] = useState(false);

    const addToReadingList = (value) => {
        setReadingList(prevState => [
            ...prevState,
            value,
            ]);
    }

    const code_section_button_click = (section_name) => {
        setShowCodeSectionComponent(true);
        setShowSearchSectionComponent(false);
        setActiveCodeSections(all_code_sections[section_name]);
        setShowDocuments(false);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            [section_name]: true,
        }));
    }

    const documents_button_click = () => {
        setShowCodeSectionComponent(true);
        setShowSearchSectionComponent(false);
        setShowDocuments(true);
        setActiveCodeSections(null);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            'documents': true,
        }));
    }

    const question_click = () => {
        setShowCodeSectionComponent(false);
        setShowSearchSectionComponent(true);
        setShowDocuments(false);
        setActiveCodeSections(null);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            'question': true,
        }));
    }

    const button_variant = (section_name) => {
        if (active_button_map[section_name]) {
            return 'secondary';
        }
        else {
            return 'outline-secondary';
        }
    }

    const large_button_variant = (section_name) => {
        if (active_button_map[section_name]) {
            return 'secondary';
        }
        else {
            return 'outline-secondary';
        }
    }

    return (
        <div class="container-xxl searchComponent">
            <div class="row">
                <div class="col-3 codeSectionsList">
                    <SFPlanningCode />
                </div>
                <div class="col-9">
                    {   formatted_address &&
                        <div class="row">
                            <div class="col-9">
                                <Card className="mt-4">
                                    <Card.Body>
                                        <>
                                            <div class="row mt-2">
                                                <div class="col fw-semibold fs-6"><i class="fa-solid fa-house"></i>&nbsp;&nbsp;{formatted_address}</div>
                                            </div>    
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <hr></hr>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-5">
                                                    <div class="row">
                                                        <div class="col-12 fw-semibold text-success">Use district zone</div>
                                                        <div class="col-12">{use_district}</div>
                                                    </div>   
                                                    <div class="row mt-2">
                                                        <div class="col-12 fw-semibold text-success">Height and bulk district</div>
                                                        <div class="col-12">{height_bulk}</div>
                                                    </div>   
                                                </div>
                                                <div class="col-7">
                                                    <div class="row">
                                                        <div class="col-12  fw-semibold text-success">Special districts</div>
                                                        <div class="col-12">
                                                            <ul>
                                                                {
                                                                    special_use_districts.map(special_district => {
                                                                        return <li> {special_district}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <hr></hr>
                                                </div>
                                            </div>
                                            <div class="row mt-2 topicSection">
                                                <div class="container">
                                                    <div class="row mb-3">
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('far')} onClick={()=>code_section_button_click('far')}>Floor area ratio</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('setback')} onClick={()=>code_section_button_click('setback')} >Front setback</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('height')} onClick={()=>code_section_button_click('height')}>Height/bulk</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('rear_yard')} onClick={()=>code_section_button_click('rear_yard')}>Rear yard</Button>
                                                            </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-3 text-center">
                                                        <Button className="topicButton" variant={button_variant('parking')} onClick={()=>code_section_button_click('parking')}>Parking</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('street_scape')} onClick={()=>code_section_button_click('street_scape')}>Streetscape</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('frontage')} onClick={()=>code_section_button_click('frontage')}>Frontage</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('open_space')} onClick={()=>code_section_button_click('open_space')}>Open space</Button>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('sun')} onClick={()=>code_section_button_click('sun')}>Sun/shadows</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('sign')} onClick={()=>code_section_button_click('sign')}>Signs</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('use')} onClick={()=>code_section_button_click('use')}>Uses</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('definition')} onClick={()=>code_section_button_click('definition')}>Definitions</Button>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="row">
                                                        <div class="col-6 text-center">
                                                            <Button className="searchButton" variant={large_button_variant('documents')} onClick={ documents_button_click }>Relevant documents</Button>
                                                        </div>    
                                                        <div class="col-6 text-center">
                                                            <Button className="searchButton" variant={large_button_variant('question')} onClick={ question_click }>Ask a question</Button>
                                                        </div>    
                                                    </div>    
                                                </div>
                                            </div>
                                        </>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-3 readingList">
                                {
                                    reading_list.length > 0 && 
                                    <Card className="mt-4">
                                        <Card.Body>
                                            <div class="row  mb-2">
                                                <div class="col-auto fw-bold">Research list </div>
                                                <div class="col text-end"><a role="button" href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/san_francisco-ca-2+(3).pdf" target="_blank"><i class="fa-solid fa-download"></i></a></div>
                                            </div>
                                            {reading_list.map(section => { 
                                                return  <>  
                                                            <div class="row mt-1"><div class="col"><hr></hr></div></div>
                                                            <div class="row">
                                                                <div class="col text-truncate" role="button"><small>{section}</small></div>
                                                            </div>
                                                        </>
                                                })}
                                        </Card.Body>
                                    </Card>
                                }
                            </div>                                          
                        </div>
                    }
                    {
                        show_code_section_component && 
                        <div class="row">
                            <div class="col-9">
                                <CodeSectionsComponent 
                                    code_sections={active_code_sections}
                                    formatted_address={formatted_address}
                                    show_documents={show_documents}
                                    addToReadingList={addToReadingList}
                                />
                            </div>
                            <div class="col-3 readingList">
                                {
                                    show_definition && 
                                        <Card className="mt-4">
                                            <Card.Body>
                                                <div class="row"><div class="col"><strong>Awning.</strong> A light roof-like structure, supported entirely by the exterior wall of 
                                                    a building; consisting of a fixed or movable frame covered with cloth, plastic, or metal; 
                                                    extending over doors, windows, and/or show windows; with the purpose of providing protection 
                                                    from sun and rain and/or embellishment of the façade; as further regulated in Section 3105 of the Building Code.
                                                </div></div>
                                            </Card.Body>
                                        </Card>
                                }
                            </div>                     
                        </div>
                    }
                    { 
                        show_search_section_component &&
                        <div class="row">
                            <div class="col-9">
                                <SearchComponent />
                            </div>
                            <div class="col-3 ">
                            </div>       
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

class AddressSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchButtonEnabled: false,
        }

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.resetAddressField = this.resetAddressField.bind(this);
    }
      
    addressChanged(e) {
        this.setState({
            searchButtonEnabled: false,
            address_field: e.target.value,
        });
    }

    resetAddressField() {
        this.autocompleteInput.current.value = "";
        this.setState({
            searchButtonEnabled: false,
        });
    }

    componentDidMount() {

        const center = { lat: 37.75953994968017, lng: -122.44697911726526 };

        // Create a bounding box with sides ~10km away from the center point
        const defaultBounds = {
            north: center.lat + 0.07,
            south: center.lat - 0.07,
            east: center.lng + 0.07,
            west: center.lng - 0.07,
        };
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
            {"types": ["geocode"], "fields": ["address_components", "formatted_address", "name", "geometry"], bounds: defaultBounds, strictBounds: true });

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        let streetNumber = '';
        let streetName = ''
        let city = '';
        let state = '';
        let zipCode = '';

        let i = 0;
        let lat = place.geometry.location.lat();
        let long = place.geometry.location.lng();

        for(; i < place.address_components.length; i++) {
            let component = place.address_components[i];
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            else if (component.types.includes("route")) {
                streetName = component.long_name;
            }
            else if (component.types.includes("locality")) {
                city = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
            }
            else if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
            }
        }

        this.props.setAddress({
            street_number: streetNumber,
            street_name: streetName,
            city: city,
            state: state,
            zip_code: zipCode,
            formatted_address: place.formatted_address,
            lat: lat,
            long: long,
        });

        this.setState({
            searchButtonEnabled: true,
        })
    }

    render() {
        const { searchButtonClick, searching_address } = this.props;
        
        return(
            <div class="container addressContainer">
                <div class="row gy-3">
                    <div class="col-12 col-md-9">
                        <div class="input-group">
                            <input type="text" class="form-control" placeHolder="address" ref={this.autocompleteInput}  id="autocomplete" onChange={ (e) => this.addressChanged(e) }/>
                            <Button className="bg-transparent text-dark clearButton" onClick={this.resetAddressField}>
                                <i class="fa fa-times"/>
                            </Button>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        {searching_address? 
                            <Button variant="primary" disabled className="addressButton">
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                &nbsp;&nbsp;Searching...
                            </Button>
                        :
                            <Button variant="primary" className="addressButton" disabled={ !this.state.searchButtonEnabled } onClick={ searchButtonClick }>
                                Search
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function SearchComponent (props) {
    const [text_box_text, setTextBoxText] = useState('');
    const [result, setResult] = useState('');
    const [waiting_for_result, setWaitingForResult] = useState(false);

    const search = () => {
        setResult('');
        setWaitingForResult(true);

        const interval = setTimeout(() => {
            setWaitingForResult(false);
            
            if (text_box_text.includes('slope')){
                setResult('answer_2');
            } 
            else if (text_box_text.includes('elevator')){
                setResult('answer_1');
            }
            else if (text_box_text.includes('gym')){
                setResult('answer_3');
            }
            else if (text_box_text.includes('sign') || text_box_text.includes('signs')){
                setResult('answer_4');
            }
        }, 2000);
    }

    return (
        <div class="container searchComponent  mt-4">
            <div class="row">
                <div class="input-group">
                    <input type="search" 
                        placeHolder="Type your question"
                        class="form-control"
                        name="search" 
                        id="search"
                        value={text_box_text}
                        onChange={ (e) => setTextBoxText(e.target.value) }
                    />
                    {   waiting_for_result ?
                            <Button variant="warning" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Searching...
                            </Button>
                        :
                        <Button variant="warning" onClick={search}><i class="fa-regular fa-paper-plane"></i></Button>    
                    }
                    
                </div>
            </div>
            
            <div class="row mt-4 p-3">
                {
                    result && <div class="col">
                    {
                        result === 'answer_1' &&
                        <Answer_1/>
                    }
                    {
                        result === 'answer_2' &&
                        <Answer_2/>
                    }
                    {
                        result === 'answer_3' &&
                        <Answer_3/>
                    }
                    {
                        result === 'answer_4' &&
                        <Answer_4/>
                    }
                </div>
                }
            </div>
        </div>
    )
}

function PlanningProjectDetails(props){

    const { setHeightBulk, setUseDistrict, setSpecialUseDistricts, setFormattedAddress, setAllCodeSections, setStage} = props;
    const [current_page, setCurrentPage] = useState('start');
    const [address, setAddress] = useState(null);
    const [searching_address, setSearchingAddress] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const submit = () => {
        
        setSubmitting(true);
        setTimeout(() =>      
            {setSubmitting(false); setStage(Stages.RESULT) },
            2000
        );
    }
    const searchButtonClick = () => {
        setSearchingAddress(true);

        let request = {
            "request_type": 'property_info',
            "request_address": address,
        }

        generic_post_api(request, PLANNING_PROPERTY_INFO)
        .then(
            result => {
                return result.data.json();
               
            })
        .then(data => {
            setFormattedAddress(data.formatted_address);
            setHeightBulk(data.height_bulk);
            setUseDistrict(data.use_district);
            setSpecialUseDistricts(data.special_use_districts);

            heightButtonClick(data.formatted_address, data.height_bulk, data.use_district, data.special_use_districts);
        })
        .catch(error => {
            setSearchingAddress(false);
        });
    }

    const heightButtonClick = (address, height, use, special) => {

        let request = {
            "request_type": 'height_info',
            'height_bulk': height,
            'use_district': use,
            'special_use_districts': special,
            'formatted_address': address,
        }

        generic_post_api(request, PLANNING_PROPERTY_INFO)
        .then(
            result => {
                return result.data.json();
               
            })
        .then(data => {
            setAllCodeSections(data);
            setSearchingAddress(false);
            setCurrentPage('code_date');
        })
        .catch(error => {
        });
    }

    return(
        <div class="planningProjectDetails container">
             <div class="row">
                <div class="col text-center display-6">Property &amp; project details</div>
            </div>
            { current_page === 'start' &&
                <div class="row mt-4">
                    <div class="col">
                        <Card>
                            <Card.Body>
                                <Card.Text className="ms-3 fw-semibold text-center">
                                    <p>Start with the project name</p>
                                </Card.Text>
                                <div class="container">
                                    <div class="row">
                                        <div class="col"><input type="text" class="form-control" placeHolder="project name"/></div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-end">
                                            <Button variant="primary" onClick={()=> setCurrentPage('address')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>    
                    </div>
                </div>
            }
            {
                current_page === 'address' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Property's address</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row ">
                                <AddressSection 
                                    setAddress={ setAddress }
                                    searchButtonClick={ searchButtonClick }
                                    searching_address={ searching_address }
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                current_page === 'code_date' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Code version date</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row">
                                    <div class="datePickerInput col text-center">
                                        <DatePicker 
                                            allowSameDay={true}
                                            inline
                                            maxDate={new Date()}
                                            showDisabledMonthNavigation
                                            className="datePicker text-center"
                                            placeholderText="Select date"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="primary" onClick={()=> setCurrentPage('current_use')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }

            {
                current_page === 'current_use' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Current use</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col text-center">
                                                <ToggleButtonGroup className="mb-2" type="radio" name="current_use" vertical>                                                    
                                                    <ToggleButton className="text-nowrap projectButton" id="current_use-radio-1" value={1} variant="outline-success"
                                                        > Vacant lot
                                                    </ToggleButton>
                                              
                                                    <ToggleButton className="text-nowrap projectButton" id="current_use-radio-2" value={2} variant="outline-success"
                                                        > Commercial  
                                                    </ToggleButton>
                                            
                                                    <ToggleButton className="text-nowrap projectButton" id="current_use-radio-3" value={3} variant="outline-success"
                                                        > Residential  
                                                    </ToggleButton>
                                            
                                                    <ToggleButton className="text-nowrap projectButton" id="current_use-radio-4" value={4} variant="outline-success"
                                                        > Mix-use
                                                    </ToggleButton>

                                                </ToggleButtonGroup>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('intended_use')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                current_page === 'intended_use' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Intended use</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col text-center">
                                                <ToggleButtonGroup className="mb-2" type="radio" name="intended_use" vertical>                                                    
                                                    
                                                    <ToggleButton className="text-nowrap projectButton" id="intended_use-radio-2" value={2} variant="outline-success"
                                                        > Commercial  
                                                    </ToggleButton>
                                            
                                                    <ToggleButton className="text-nowrap projectButton" id="intended_use-radio-3" value={3} variant="outline-success"
                                                        > Residential  
                                                    </ToggleButton>
                                            
                                                    <ToggleButton className="text-nowrap projectButton" id="intended_use-radio-4" value={4} variant="outline-success"
                                                        > Mix-use
                                                    </ToggleButton>

                                                </ToggleButtonGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('use_description')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                current_page === 'use_description' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>In a few words, describe the intended use of the project</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <input type="text" placeholder="Use description" class="form-control"></input>
                                    </div>
                                </div>
                                
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="primary" onClick={()=> setCurrentPage('use_detail')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                current_page === 'use_detail' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Choose all uses that apply</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-1">
                                    <div class="col 10">
                                        <div class="row mt-1 fw-bold">Automotive repair</div>
                                        <div class="row mt-1">Perform services like minor repairs, engine repair, power train installation, reconditioning, collision service, and full-body paint spraying. All work must occur in an enclosed building with no openings except windows or required exits, located at least 50 feet from residential areas.</div>
                                    </div>
                                    <div class="col-2 text-center">
                                        <ToggleButtonGroup className="mb-2" type="checkbox" name="repair">
                                            <ToggleButton className="text-nowrap" id="repair-radio-1" value={1} variant="outline-success"
                                                > X
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-1"><hr></hr></div>
                                <div class="row mt-1">
                                    <div class="col 10">
                                        <div class="row mt-1 fw-bold">Automotive service station</div>
                                        <div class="row mt-1">Sell motor fuels, lubricating oils, and performing minor repairs, excluding major services like engine or power train repair. Repairs are limited to three enclosed bays in buildings with no openings, except required exits, and must be 40 feet from residential areas.</div>
                                    </div>
                                    <div class="col-2 text-center">
                                        <ToggleButtonGroup className="mb-2" type="checkbox" name="service">
                                            <ToggleButton className="text-nowrap" id="service-radio-1" value={1} variant="outline-success"
                                                > X
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-1"><hr></hr></div>
                                <div class="row mt-1">
                                    <div class="col 10">
                                        <div class="row mt-1 fw-bold">Electric Vehicle Charging Location</div>
                                        <div class="row mt-1">Provide electricity to electric motor vehicles through more than one Electric Vehicle Charging Stations on a retail basis to the general public as a primary use. Electric Vehicle Charging Locations may include ancillary services, including but not limited to restrooms, self-service vending, and limited retail amenities primarily for the benefit of customers charging their vehicles.</div>
                                    </div>
                                    <div class="col-2 text-center">
                                        <ToggleButtonGroup className="mb-2" type="checkbox" name="electric">
                                            <ToggleButton className="text-nowrap" id="electric-radio-1" value={1} variant="outline-success"
                                                > X
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-1"><hr></hr></div>
                                <div class="row mt-1">
                                    <div class="col 10">
                                        <div class="row mt-1 fw-bold">Gas</div>
                                        <div class="row mt-1"> Provide motor fuels, lubricating oils, air, and water directly into motor vehicles and without providing Automotive Repair services, and which also includes self-service operations that sell motor fuel only. </div>
                                    </div>
                                    <div class="col-2 text-center">
                                        <ToggleButtonGroup className="mb-2" type="checkbox" name="gas">
                                            <ToggleButton className="text-nowrap" id="gas-radio-1" value={1} variant="outline-success"
                                                > X
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="primary" onClick={()=> setCurrentPage('lot')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {/* {
                current_page === 'height' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Height &amp; bulk</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col-6">Current property height</div>
                                    <div class="col-2"><input type="text" class="form-control" /></div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Proposed property height</div>
                                    <div class="col-2"><input type="text" class="form-control"/></div>
                                </div>
                                
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('lot')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            } */}
            {
                current_page === 'lot' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Current</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col-6">Is the property on a narrow street</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="narrow">
                                            <ToggleButton
                                                id="tbg-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="tbg-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="tbg-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                
                                <div class="row mt-3">
                                    <div class="col-6">Is this a corner lot</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="corner">
                                            <ToggleButton
                                                id="corner-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="corner-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="corner-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('frontage')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                current_page === 'frontage' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="fw-semibold text-center">
                                <p>Proposed</p>
                            </Card.Text>
                            <div class="container">
                                
                                <div class="row mt-3">
                                    <div class="col-6">Demolishing, removing, combining existing habitable space</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="Repaving">
                                            <ToggleButton
                                                id="Repaving-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Repaving-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Repaving-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Adding any of the following</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="checkbox" name="awning">
                                            <ToggleButton
                                                id="awning-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Awning  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="awning-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Canopy    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="awning-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Marquee
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 text-end">
                                        <Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {
                                            !submitting ? 
                                                <Button variant="primary" onClick={submit}>Submit&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button>
                                            :
                                                <Button variant="primary" onClick={submit}>Submitting&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></Button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
        </div>
    )
}

export function SFPlanningCode() {
    const [expand, setExpand] = useState(false);
    const [show_section, setShowSection] = useState(false);
    const [hide_code, setHideCode] = useState(true);

    return (
        <>
        {/* { show_section && */}
            <Modal
                        show={show_section}
                        onHide={() => setShowSection(false)}
                        size="lg"
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="fullSectionText"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>SEC. 131. Legislated setback lines</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                        <div class="container">
                            <div class="row">
                                <div>
                                    <ol type="a">
                                        <li>The legislated setback lines along specific street and alley frontages established by ordinance and resolution pursuant to former Article 4 of the City Planning Code and earlier provisions of law are hereby continued in effect as regulations of the City Planning Code, regardless of the regulations for the use districts in which such street and alley frontages are located, and said ordinances and resolutions are expressly incorporated herein by reference as though fully set forth.</li>
                                        <li>The obstructions permitted within such legislated setback lines shall be as described in Sections 132 and 136 of this Code. No other obstruction shall be constructed, placed or maintained within a legislated setback line.</li>
                                        <li>The procedures for establishment, abolition or modification of a legislated setback line shall be as specified in Sections 302 and 306 through 306.5 for amendments to this Code</li>
                                        <li>In case of any conflict between the requirements of a legislated setback line and a front setback area established by Section 132 of this Code, the more restrictive requirements shall prevail.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        </Modal.Body>
                    </Modal>
                
        {/* } */}
            <Card className="mt-4 ">
                <Card.Body>
                    <div class="row mb-4"><div class="col fw-bold">SF Planning Code&nbsp;<i class="fa-solid fa-caret-down" role="button" onClick={() => setHideCode(!hide_code)}></i></div></div>
                    {
                        !hide_code && 
                        <>
                            <div class="row mb-1" role="button"><div class="col">Preface to the planning code</div></div>
                            <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                            <div class="row mb-1" role="button"><div class="col-1 me-1"><i class="fa-regular fa-square-plus fa-sm"></i></div> <div class="col ps-1">Article 1: General zoning provisions</div></div>
                            <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                            
                            <div class="row mb-1" role="button">
                                <div class="col-1 me-1">
                                {
                                    !expand ? 
                                    <i class="fa-regular fa-square-plus fa-sm" onClick={() => setExpand(true)}></i>
                                    :
                                    <i class="fa-regular fa-square-minus fa-sm" onClick={() => setExpand(false)}></i>
                                }
                                </div> 
                                <div class="col ps-1">Article 1.2: Dimensions, areas, and open spaces</div>
                            </div>
                            {
                                expand &&
                                <div class="ms-5">
                                    <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                                    <div class="row mb-1" role="button"><div class="col">SEC. 121. Minimum lot width and area</div></div>
                                    <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                                    <div class="row mb-1" role="button"><div class="col">SEC. 121.6. Large scale retail uses</div></div>
                                    <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                                    <div class="row mb-1" role="button"><div class="col">SEC. 122. Height and bulk limitations</div></div>
                                    <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                                    <div class="row mb-1" role="button" onClick={() => setShowSection(true)}><div class="col">SEC. 131. Legislated setback lines</div></div>
                                </div>
                            }

                            <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                            <div class="row mb-1" role="button"><div class="col-1 me-1"><i class="fa-regular fa-square-plus fa-sm"></i></div> <div class="col ps-1">Article 1.5: Transportation, Off-street parking, and loading</div></div>
                            <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                            <div class="row mb-1" role="button"><div class="col-1 me-1"><i class="fa-regular fa-square-plus fa-sm"></i></div> <div class="col ps-1">Article 1.7: Compliance</div></div>
                            <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                            <div class="row mb-1" role="button"><div class="col-1 me-1"><i class="fa-regular fa-square-plus fa-sm"></i></div> <div class="col ps-1">Article 2: Use districts</div></div>
                            <div class="row p-0 mb-1"><hr className="p-0 mb-1"></hr></div>
                            <div class="row mb-1" role="button"><div class="col-1 me-1"><i class="fa-regular fa-square-plus fa-sm"></i></div> <div class="col ps-1">Article 2.5: Height and bulk districts</div></div>
                        </>
                    }
                    
                </Card.Body>
            </Card>
        </>
    );
}

function Section_607_2() {
    return (
        <div class="sub-section-container">
            Signs located in Mixed Use Districts shall be regulated as provided herein, except for signs in Residential Enclave
            Districts, which are regulated by Section 606, and those signs which are exempted by Section 603. Signs not
            specifically regulated in this Section 607.2 shall be prohibited. In the event of conflict between the provisions of
            Section 607.2 and other provisions of Article 6, the provisions of Section 607.2 shall prevail in Mixed Use
            Districts.
            <ol type="a">
                <li>Purposes and Findings. In addition to the purposes stated in Sections 101 and 601 of this Code, the
                    following
                    purposes apply to Mixed Use Districts. These purposes constitute findings that form a basis for regulations
                    and
                    provide guidance for their application.
                    <ol>
                        <li>As Mixed Use Districts change, they need to maintain their attractiveness to customers and potential
                            new
                            businesses alike. Physical amenities and a pleasant appearance will profit both existing and new
                            enterprises.
                        </li>
                        <li>The character of signs and other features projecting from buildings is an important part of the
                            visual appeal of
                            a street and the general quality and economic stability of the area. Opportunities exist to relate
                            these signs and
                            projections more effectively to street design and building design. These regulations establish a
                            framework that will
                            contribute toward a coherent appearance of Mixed Use Districts.
                        </li>
                        <li>Mixed Use Districts are typically mixed use areas with commercial units on the ground or lower
                            stories and
                            residential uses on upper stories or have housing and commercial and industrial activities
                            interspersed. Although
                            signs and other advertising devices are essential to a vital commercial district, they should not be
                            allowed to
                            interfere with or diminish the livability of residential units within a Mixed Use District or in
                            adjacent
                            residential districts.
                        </li>
                        <li>The scale of most Mixed Use Districts as characterized by building height, bulk, and appearance, and
                            the width
                            of streets and sidewalks differs from that of other commercial and industrial districts. Sign sizes
                            should relate
                            and be compatible with the surrounding district scale.
                        </li>
                    </ol>
                </li>
                <li>Signs or Sign Features Not Permitted in Mixed Use Districts. General Advertising Signs are not permitted in
                    Mixed Use districts. Roof Signs as defined in Section 602, Wind Signs as defined in Section 602, and Signs
                    on
                    Canopies, as defined in Section 136.1(b) of this Code, are not permitted in Mixed Use Districts. No Sign
                    shall have
                    or consist of any moving, rotating, or otherwise physically animated part, or lights that give the
                    appearance of
                    animation by flashing, blinking, or fluctuating. In addition, all Signs or sign features not otherwise
                    specifically
                    regulated in this Section 607.2 shall be prohibited.
                </li>
                <li>Identifying Signs. Identifying Signs, as defined in Section 602, shall be permitted in all Mixed Use
                    Districts
                    subject to the limits set forth below.
                    <ol>
                        <li>One Sign per lot shall be permitted and such Sign shall not exceed 20 square feet in area. The Sign
                            may be a
                            Freestanding Sign, if the building is recessed from the Street Property Line, or may be a Wall Sign
                            or a projecting
                            Sign. The existence of a Freestanding Identifying Sign shall preclude the erection of a Freestanding
                            Business Sign
                            on the same lot. A Wall Sign or projecting Sign shall be mounted on the first-story level; a
                            Freestanding Sign shall
                            not exceed 15 feet in height. Such Signs may be Nonilluminated, Indirectly Illuminated, or Directly
                            Illuminated.
                        </li>
                        <li>One Sign identifying a shopping center or shopping mall shall be permitted subject to the conditions
                            in
                            subsection (c)(1), but shall not exceed 30 square feet in area. Such Signs may be Nonilluminated,
                            Indirectly
                            Illuminated, or Directly Illuminated during the hours of operation of the businesses in the shopping
                            center or
                            shopping mall.
                        </li>
                    </ol>
                </li>
                <li>Nameplate. One nonilluminated or directly illuminated Nameplate, as defined in Section 602 of this Code, not
                    exceeding an area of two square feet, shall be permitted for each noncommercial use in Mixed Use Districts.
                </li>
                <li>General Advertising Signs. General Advertising Signs, as defined in Section 602, are not permitted in Mixed
                    Use
                    Districts.
                </li>
                <li>Business Signs. Business Signs, as defined in Section 602, shall be permitted in all Mixed Use Districts
                    subject
                    to the limits set forth below.
                    <ol>
                        <li>
                            Chinatown Residential Neighborhood Commercial District.
                            <ol type="A">
                                <li>
                                    Window Signs. The total Area of all Window Signs shall not exceed one-third the area of the
                                    window on or in
                                    which the Signs are located. Such Signs may be Nonilluminated, Indirectly Illuminated, or
                                    Directly Illuminated.
                                </li>
                                <li>Wall Signs. The Area of all Wall Signs shall not exceed one square foot per foot of street
                                    frontage occupied by
                                    the business measured along the wall to which the Signs are attached, or 50 square feet for
                                    each street frontage,
                                    whichever is less; provided, however, that in no case shall the Wall Sign or combination of
                                    Wall Signs cover more
                                    than 75% of the surface of any wall, excluding openings. The Height of any Wall Sign shall
                                    not exceed 15 feet or the
                                    height of the wall to which it is attached. Such Signs may be Nonilluminated or Indirectly
                                    Illuminated; or during
                                    business hours, may be Directly Illuminated.
                                </li>
                                <li>Projecting Signs. The number of projecting Signs shall not exceed one per business. The Area
                                    of such Sign shall
                                    not exceed 24 square feet. The Height of such Sign shall not exceed 15 feet or the height of
                                    the wall to which it is
                                    attached. No part of the Sign shall project more than 75% of the horizontal distance from
                                    the Street Property Line
                                    to the curbline, or six feet six inches, whichever is less. The Sign may be Nonilluminated
                                    or Indirectly
                                    Illuminated, or during business hours, may be Directly Illuminated.
                                </li>
                                <li>Signs on Awnings. Sign copy may be located on permitted Awnings in addition to Wall Signs
                                    and projecting Signs.
                                    The area of such sign copy shall not exceed 20 square feet. Such sign copy may be
                                    nonilluminated or indirectly
                                    illuminated.
                                </li>
                            </ol>
                        </li>
                        <li>Chinatown Visitor Retail District.
                            <ol type="A">
                                <li>Window Signs. The total Area of all Window Signs shall not exceed one-third the area of the
                                    window on or in
                                    which the Signs are located. Such Signs may be Nonilluminated, Indirectly Illuminated, or
                                    Directly Illuminated.
                                </li>
                                <li>Wall Signs. The Area of all Wall Signs shall not exceed two square feet per foot of street
                                    frontage occupied by
                                    the use measured along the wall to which the Signs are attached, or 100 square feet for each
                                    street frontage,
                                    whichever is less. The Height of any Wall Sign shall not exceed 24 feet, or the height of
                                    the wall to which it is
                                    attached, or the height of the lowest of any residential windowsill on the wall to which the
                                    Sign is attached,
                                    whichever is lower. Such Signs may be Nonilluminated, Indirectly Illuminated, or Directly
                                    Illuminated.
                                </li>
                                <li>Projecting Signs. The number of projecting Signs shall not exceed one per business. The Area
                                    of such Sign shall
                                    not exceed 24 square feet. The Height of such Sign shall not exceed 24 feet, or the height
                                    of the wall to which it
                                    is attached, or the height of the lowest of any residential windowsill on the wall to which
                                    the Sign is attached,
                                    whichever is lower. No part of the Sign shall project more than 75% of the horizontal
                                    distance from the Street
                                    Property Line to the curbline, or six feet six inches, whichever is less. Such Signs may be
                                    Nonilluminated or
                                    Indirectly Illuminated; or during business hours, may be Directly Illuminated.
                                </li>
                                <li>Signs on Awnings and Marquees. Sign copy may be located on permitted Awnings or Marquees in
                                    addition to
                                    projecting Signs. The area of such sign copy shall not exceed 30 square feet. Such sign copy
                                    may be nonilluminated
                                    or indirectly illuminated, except that sign copy on Marquees for Movie Theaters or places of
                                    Entertainment may be
                                    directly illuminated during business hours.
                                </li>
                                <li>Freestanding Signs and Sign Towers. One Freestanding Sign or Sign Tower per lot shall be
                                    permitted in lieu of a
                                    projecting Sign, if the building or buildings are recessed from the Street Property Line.
                                    The existence of a
                                    Freestanding Business Sign shall preclude the erection of a Freestanding Identifying Sign on
                                    the same lot. The area
                                    of such Freestanding Sign or Sign Tower shall not exceed 20 square feet nor shall the Height
                                    of the Sign exceed 24
                                    feet. No part of the Sign shall project more than 75% of the horizontal distance from the
                                    Street Property Line to
                                    the curbline, or six feet, whichever is less. Such Signs may be Nonilluminated or Indirectly
                                    Illuminated; or during
                                    business hours, may be Directly Illuminated.
                                </li>
                            </ol>
                        </li>
                        <li>Chinatown Community Business District, Eastern Neighborhoods, South of Market Mixed Use Mixed Use
                            Districts, and
                            the Downtown Residential Districts.
                            <ol type="A">
                                <li>Window Signs. The total Area of all Window Signs shall not exceed one-third the area of the
                                    window on or in
                                    which the Signs are located. Such Signs may be Nonilluminated, Indirectly Illuminated, or
                                    Directly Illuminated.
                                </li>
                                <li>
                                    Wall Signs.
                                    <ol type="i">
                                        <li>In districts other than the Urban Mixed Use District. The Area of all Wall Signs
                                            shall not exceed three square
                                            feet per foot of street frontage occupied by the use measured along the wall to
                                            which the Signs are attached, or 150
                                            square feet for each street frontage, whichever is less; provided, however, that in
                                            no case shall the Wall Sign or
                                            combination of Wall Signs cover more than 75% of the surface of any wall, excluding
                                            openings. The Height of any Wall
                                            Sign shall not exceed 24 feet, or the height of the wall to which it is attached, or
                                            the height of the lowest of any
                                            residential windowsill on the wall to which the Sign is attached, whichever is
                                            lower. Such Signs may be
                                            Nonilluminated, Indirectly Illuminated, or Directly Illuminated.
                                        </li>
                                        <li>In the Urban Mixed Use District. The Area of all Wall Signs shall not exceed three
                                            square feet per foot of
                                            street frontage occupied by the use measured along the wall to which the Signs are
                                            attached for up to 50 feet of
                                            street frontage, and an additional one square foot per foot of street frontage
                                            thereafter; provided, however, that
                                            in no case shall the Wall Sign or combination of Wall Signs cover more than 75% of
                                            the surface of any wall,
                                            excluding openings. The Height of any Wall Sign shall not exceed 60 feet, or the
                                            height of the wall to which it is
                                            attached, or the height of the lowest of any residential windowsill on the wall to
                                            which the Sign is attached,
                                            whichever is lower. Such Signs may be Nonilluminated, Indirectly Illuminated, or
                                            Directly Illuminated.
                                        </li>
                                    </ol>
                                </li>
                                <li>Projecting Signs. The number of projecting Signs shall not exceed one per business. The Area
                                    of such sign or
                                    Signs combined when there are multiple Signs shall not exceed 32 square feet. The Height of
                                    the Sign shall not
                                    exceed 24 feet, or the height of the wall to which it is attached, or the height of the
                                    lowest of any residential
                                    windowsill on the wall to which the Sign is attached, whichever is lower. No part of the
                                    Sign shall project more
                                    than 75% of the horizontal distance from the Street Property Line to the curbline, or six
                                    feet six inches, whichever
                                    is less. Such Signs may be Non-illuminated, Indirectly Illuminated, or Directly Illuminated.
                                </li>
                                <li>Sign Copy on Awnings and Marquees. Sign copy may be located on permitted Awnings or Marquees
                                    in lieu of
                                    projecting Signs, except that in the Chinatown Community Business District, sign copy may be
                                    located on permitted
                                    Awnings or Marquees in addition to projecting Signs. The area of such sign copy shall not
                                    exceed 40 square feet.
                                    Such sign copy may be nonilluminated or indirectly illuminated; except that sign copy on
                                    Marquees for Movie Theaters
                                    or places of Entertainment may be directly illuminated during business hours.
                                </li>
                                <li>Freestanding Signs and Sign Towers. One Freestanding Sign or Sign Tower per lot shall be
                                    permitted in lieu of a
                                    projecting sign if the building or buildings are recessed from the Street Property Line. The
                                    existence of a
                                    Freestanding Business Sign shall preclude the erection of a Freestanding Identifying Sign on
                                    the same lot. The Area
                                    of such Freestanding Sign or Sign Tower shall not exceed 30 square feet nor shall the Height
                                    of the Sign exceed 24
                                    feet. No part of the Sign shall project more than 75% of the horizontal distance from the
                                    Street Property Line to
                                    the curbline, or six feet, whichever is less. Such Signs may be Nonilluminated or Indirectly
                                    Illuminated, or during
                                    business hours, may be Directly Illuminated.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>Special Sign Districts. Additional controls apply within certain Mixed Use Districts that are designated as
                    Special Sign Districts. The designations, locations, and boundaries of these Special Sign Districts are
                    provided on
                    Sectional Map SSD of the Zoning Map of the City and County of San Francisco, and are described within
                    Sections 608.1
                    through 608.10 of this Code.
                </li>
                <li>
                    Other Sign Requirements. Within Mixed Use Districts, the following additional requirements shall apply:
                    <ol>
                        <li>Temporary Signs. The provisions of Section 607.1(g) of this Code shall apply.</li>
                        <li>Special Standards for Automotive Gas and Service Stations. The provisions of Section 607.1(f)(4) of
                            this Code
                            shall apply.
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    )
}
export default PlanningCodeSearch680;