/* global google */
// do not delete above line. Needed for google address to function

import React, { Component, useState, useRef, useEffect } from "react";
import { PLANNING_PROPERTY_INFO } from 'utils/constants';
import ContactUs from 'pages/contact_us';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Spinner, Modal, ButtonGroup, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import user_profile_place_holder from 'assets/reputation/user_profile_place_holder.png';
import { generic_put_api_file, generic_get_api, generic_delete_api, generic_put_api, generic_post_api } from "api/generic_api";
import { formatPhoneNumber } from "utils/formatters";
import { isValidPhoneNumber, isValidEmail } from "utils/helpers";
import { CodeSectionsBySection, AdminBulletins, CodeSectionsByRoom } from "utils/code_sections";
import plan from 'assets/temp/plan.png'
import { SFPlanningCode } from "./planning_code_search_680";


var get_yesterday_date = () => {
    var today = new Date();
    today.setDate(today.getDate() - 1);

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
}

const Stages = {
    QUESTIONS: 'questions',
    RESULT: 'result',
}

function Answer_1(props){
    return(    <Card>
                        <Card.Body>
                            
                            <div class="answerEnvelop">
                                <div class="mb-2">
                                    According to <strong>SEC. 260</strong>, certain features are exempt from height limitations, provided specific conditions are met.
                                </div>

                                <div class="fw-bold">
                                    Horizontal Area Limitation
                                </div>
                                <ul>
                                    <li>The total horizontal area of all exempt features must not exceed 30% of the roof's horizontal area.</li>
                                    <li>Within the first 10 feet of depth from the building's front wall, the sum of the horizontal areas of these
                                        features must not exceed 20% of the roof's horizontal area in that section.
                                    </li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Specific Exempt Features
                                </div>
                                <ul>
                                    <li>Includes elevator, stair, and mechanical penthouses, fire towers, skylights, and dormer windows.</li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Height Limitations for Exempt Features
                                </div>
                                <ul>
                                    <li>Exemption is limited to the top 16 feet of these features if the building height limit is 65 feet or less.
                                    </li>
                                    <li>If the building height limit is over 65 feet, the exemption applies to the top 20 feet of these features.
                                    </li>
                                    <li>For elevator penthouses, the exemption is limited to the top 16 feet, restricted to the footprint of the
                                        elevator shaft, regardless of the building's height limit.
                                    </li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Design Consistency
                                </div>
                                <ul>
                                    <li>Elevator penthouses in Residential Districts must comply with the "Residential Design Guidelines" as adopted
                                        by the Planning Commission.
                                    </li>
                                </ul>

                                <div class="fw-bold mt-2">
                                    Further Height Exemption
                                </div>
                                <ul>
                                    <li>The Zoning Administrator may grant additional height exemptions for elevator penthouses in buildings with
                                        height limits over 65 feet after a public hearing.
                                    </li>
                                    <li>This exemption is only permissible if necessary to comply with state or federal laws or regulations.</li>
                                    <li>Requests for height exemptions in Residential or Neighborhood Commercial Districts must follow the
                                        neighborhood notification requirements of Section 311 of the Code.
                                    </li>
                                </ul>
                                <div class="mt-2 fw-bold">Sources:</div>
                                <ul>
                                    <li><div role="button">SEC. 260: Height Limits: Measurement <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>
    )
}

function Answer_2(props){
    return(    <Card>
                        <Card.Body>
                            
                            <div class="answerEnvelop">
                                <div class="mb-2">
                                    According to <strong>SEC. 135</strong>, the slope of any usable open space, whether it is private or common, must not exceed five percent.
                                </div>

                                <div class="mt-2 fw-bold">Sources:</div>
                                <ul>
                                    <li><div role="button">SEC. 135: Usable open space for dwelling units and group housing <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></div></li>
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>
    )
}

function Answer_3(props){
    const [show_modal, setShowModal] = useState(false);

    return(    
        <>  
            <Modal
                show={show_modal}
                onHide={() => setShowModal(false)}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="fullSectionText"
            >
                <Modal.Header closeButton>
                    <Modal.Title>SEC. 134 REAR YARDS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Section_134 />
                </Modal.Body>
            </Modal>
            <Card>
                <Card.Body>
                    
                    <div class="answerEnvelop">
                        <div class="mb-2">
                            <p><strong>Section 134 Interpretation (June 1990, Revised March 2021):</strong></p>
                            <ul>
                                <li>It is a policy to allow the enclosure of the space under a legal nonconforming enclosed projection extending into the required rear yard.</li>
                                <li>A fence or enclosure cannot exceed a height of 10 feet. If the void space extends higher than 10 feet above the existing grade at any point, it cannot be enclosed without obtaining a variance.</li>
                            </ul>

                            <p><strong>Section 311 Interpretation (April 1996, Zoning Administrator Bulletin No. 4):</strong></p>
                            <ul>
                                <li>There is a "general rule" exemption stating that anything not visible from any off-site land or structure is exempt from Section 311 notification.</li>
                                <li>However, any "change of use," which Section 311 defines as an "alteration," is subject to this notification. The interpretation references a previous interpretation from 1993 regarding when adding a dwelling unit constitutes a change of use.</li>
                            </ul>
                        </div>

                        <div class="mt-2 fw-bold">Sources:</div>
                        <ul>
                            <li><div role="button">SEC. 134: Rear yards in R, RC districts <a onClick={() => setShowModal(true)}><i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></li>
                            <li><div role="button">Zoning Administrator letter of determination (April 21, 2021) <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/3882_za_letter.pdf" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></li>
                            <li><div role="button">Zoning Administrator bulletin no. 4 <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/ZAB_04_311_312_Notification.pdf" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></li>
                            <li><div role="button">Zoning Administrator interpretations (March 22, 2021) <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/ZA_Interpretation_repeal_ZAB-01_Dwelling_Units_Revisions-032221.pdf" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></li>
                        </ul>
                    </div>
                </Card.Body>
            </Card>
        </> 
    )
}

class PlanningCodeSearch52 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_stage: Stages.QUESTIONS,
            height_bulk: '',
            use_district: '',
            special_use_districts: '',
            formatted_address: '',
            all_code_sections: null,
        };
        this.setStage = this.setStage.bind(this);

        this.setHeightBulk = this.setHeightBulk.bind(this);
        this.setUseDistrict = this.setUseDistrict.bind(this);
        this.setSpecialUseDistricts = this.setSpecialUseDistricts.bind(this);
        this.setFormattedAddress = this.setFormattedAddress.bind(this);
        this.setAllCodeSections = this.setAllCodeSections.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'planning_code_search'});
    }

    setStage(stage) {
        this.setState({ current_stage: stage });
    }

    setHeightBulk(hb) {
        this.setState({height_bulk: hb});
    }

    setUseDistrict(use_district) {
        this.setState({use_district: use_district});
    }

    setSpecialUseDistricts(special_use_districts) {
        this.setState({special_use_districts: special_use_districts});
    }

    setFormattedAddress(formatted_address) {
        this.setState({formatted_address: formatted_address});
    }

    setAllCodeSections(all_code_sections) {
        this.setState({all_code_sections: all_code_sections});
    }

    render() {
        const {current_stage} = this.state;

        return(
            <div class="planningCodeSearchEnvelope mt-5">
                <div class="container-xxl planningCodeSearchPage">
                    {
                        current_stage === Stages.QUESTIONS && 
                        <PlanningProjectDetails 
                            setStage={this.setStage}
                            setHeightBulk={this.setHeightBulk}
                            setUseDistrict={this.setUseDistrict}
                            setSpecialUseDistricts={this.setSpecialUseDistricts}
                            setFormattedAddress={this.setFormattedAddress}
                            setAllCodeSections={this.setAllCodeSections}
                        />
                    }
                    {
                        current_stage === Stages.RESULT && 
                            <AddressFlowComponent 
                                setStage={this.setStage}
                                height_bulk={this.state.height_bulk}
                                use_district={this.state.use_district}
                                special_use_districts={this.state.special_use_districts}
                                formatted_address={this.state.formatted_address}
                                all_code_sections={this.state.all_code_sections}
                            />
                    }
                    
                </div>
                
            </div>
        )
    }
}

function FullSectionModal(props) {
    const {active_section, setActiveSection} = props;
    
    if (!active_section) {
        return null;
    }

    return (      
            <Modal
                show={active_section}
                onHide={() => setActiveSection(null)}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="fullSectionText"
            >
                <Modal.Header closeButton>
                    <Modal.Title>SEC. {active_section.section_id} {active_section.section_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        active_section.section_id === '132_1' ? 
                            <Section132_1 />
                        :
                        <div class="container">
                            <div class="row">
                                <div class="col" >
                                    <span dangerouslySetInnerHTML={{__html: active_section.formatted_section_text}} />
                                </div>
                            </div>
                            {
                                active_section.references.length > 0 && 
                                <>
                                    <div class="row mt-3">
                                        <div class="col fw-bold">References</div>
                                    </div>
                                    <ul>
                                    {
                                        active_section.references.map(reference => {
                                            return <li className="text-decoration-underline" role="button">SEC. {reference.section_id} {reference.section_name}</li>
                                        })
                                    }
                                    </ul>
                                </>
                            }
                        </div>
                    }
                </Modal.Body>
            </Modal>
    )
}

function CodeSectionsComponent(props) {
    const [active_section, setActiveSection] = useState(null);

    return (<div>
                <FullSectionModal
                    active_section={active_section}
                    setActiveSection={setActiveSection}
                />
           
            { props.code_sections && !props.show_documents &&
                <div class="codeSectionList mt-4">
                    {
                        props.code_sections.map(section => {
                            return  <div class="mb-4 p-2 border rounded">
                                        <div class="row">
                                            <div class="col fw-semibold">
                                                <i class="fa-regular fa-file-lines fa-lg"/>&nbsp;&nbsp;SEC. {section.section_id} {section.section_name}
                                            </div>
                                        </div>       
                                        <div class="row mt-1 p-2 ms-3">
                                            { section.section_id === '136.1' ?
                                                <Section_136_1_summary 
                                                    setShowDefinition={props.setShowDefinition}
                                                />
                                                :
                                                <span dangerouslySetInnerHTML={{__html: section.summary}} />
                                            }
                                        </div>
                                        <div class="row mt-1">
                                            <div class="col text-decoration-underline"><a role="button" onClick={() => setActiveSection(section)}>Read full section</a></div>
                                            <div class="col text-decoration-underline text-end">
                                                <Button variant="secondary" size="sm" onClick={() => props.addToReadingList("SEC. " + section.section_id + " " + section.section_name)}><i class="fa-solid fa-plus"></i></Button>
                                            </div>
                                        </div>
                                    </div>
                        })
                    }
                </div>
            }
            { props.show_documents && props.formatted_address && props.formatted_address.startsWith('52 Clipper') &&
                <div class="codeSectionList">
                    <div class="row mt-4">
                        <div class="col text-secondary fs-5 text-center mb-2">Zoning Administrator letters of determination</div>
                    </div>
                    <div class="mb-4 p-2 border rounded">
                        <div class="row mb-1"><div class="col"><span class="fw-semibold">Topics: </span>Rear yard, In-fill, RH-2, legal non-conforming</div></div>
                        <div class="row mb-1"><div class="col"><span class="fw-semibold">Address: </span>3882 25th Street</div></div>
                        <div class="row mb-1"><div class="col fw-semibold">Documents</div></div>
                        <div class="row mb-1 ms-2">
                            <div class="col">- <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/3882_request.pdf" target="_blank" class="text-decoration-none  text-black">Owner/representative inquiry letter <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div>
                        </div>
                        <div class="row mb-1 ms-2">
                            <div class="col">- <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/3882_plans.pdf" target="_blank" class="text-decoration-none  text-black">Supported documentation (plans) <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div>
                        </div>
                        <div class="row mb-1 ms-2">
                            <div class="col">- <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/3882_za_letter.pdf" target="_blank" class="text-decoration-none  text-black">Zoning Administrator letter of determination <i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col text-secondary fs-5 text-center mb-2">Planning bulletins</div>
                    </div>
                    <div class="mb-4 p-2 border rounded">
                        <div class="row mb-1"><div class="col fw-semibold "><i class="fa-regular fa-file-lines fa-lg"/> <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/ZAB_05_Buildable_Area.pdf" target="_blank" class="text-decoration-none  text-black">Buildable Area for Lots in RH, RM, RC, and RTO Districts&nbsp;<i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></div>
                        <div class="row mb-1 ms-2">
                            <div class="col">This ZA  Bulletin is intended to provide a basic instruction on how the Planning Code limits the size and location of structures on residentially zoned lots, i.e. what is the “buildable area” of the lot.</div>
                        </div>
                    </div>
                    <div class="mb-4 p-2 border rounded">
                        <div class="row mb-1"><div class="col fw-semibold "><i class="fa-regular fa-file-lines fa-lg"/> <a href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/ZAB_01_Rooms_Down-repealed032221.pdf" target="_blank" class="text-decoration-none  text-black">Zoning Administrator (ZA) Bulletin 1: Developing Ground Floor Accessory Rooms In Residential Buildings - REPEALED&nbsp;<i class="fa-solid fa-arrow-up-right-from-square fa-sm"/></a></div></div>
                        <div class="row mb-1 ms-2">
                            <div class="col">The Zoning Administrator repealed ZA Bulletin No. 1 on March 22, 2021</div>
                        </div>
                    </div>
                </div>
            }
    </div>)
}

function AddressFlowComponent(props) {

    const {height_bulk, use_district, special_use_districts, formatted_address, all_code_sections} = props;

    const [active_code_sections, setActiveCodeSections] = useState(null);

    const [show_code_section_component, setShowCodeSectionComponent] = useState(false);
    const [show_search_section_component, setShowSearchSectionComponent] = useState(false);
    const [active_button_map, setActiveButtonMap] = useState({});
    const [show_documents, setShowDocuments] = useState(false);
    const [reading_list, setReadingList] = useState([]);
    const [show_definition, setShowDefinition] = useState(false);

    const addToReadingList = (value) => {
        setReadingList(prevState => [
            ...prevState,
            value,
            ]);
    }

    const code_section_button_click = (section_name) => {
        setShowDefinition(false);
        setShowCodeSectionComponent(true);
        setShowSearchSectionComponent(false);
        setActiveCodeSections(all_code_sections[section_name]);
        setShowDocuments(false);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            [section_name]: true,
        }));
    }

    const documents_button_click = () => {
        setShowCodeSectionComponent(true);
        setShowSearchSectionComponent(false);
        setShowDocuments(true);
        setActiveCodeSections(null);
        setShowDefinition(false);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            'documents': true,
        }));
    }

    const question_click = () => {
        setShowCodeSectionComponent(false);
        setShowSearchSectionComponent(true);
        setShowDocuments(false);
        setActiveCodeSections(null);
        setShowDefinition(false);

        for (const [key, value] of Object.entries(active_button_map)) {

            setActiveButtonMap(prevState => ({
                ...prevState,
                [key]: false,
            }));
        }

        setActiveButtonMap(prevState => ({
            ...prevState,
            'question': true,
        }));
    }

    const button_variant = (section_name) => {
        if (active_button_map[section_name]) {
            return 'secondary';
        }
        else {
            return 'outline-secondary';
        }
    }

    const large_button_variant = (section_name) => {
        if (active_button_map[section_name]) {
            return 'secondary';
        }
        else {
            return 'outline-secondary';
        }
    }

    return (
        <div class="container-xxl searchComponent ">
            <div class="row">
                <div class="col-3 codeSectionsList">
                    <SFPlanningCode />
                </div>
                <div class="col-9">
                    { formatted_address &&
                        <div class="row">
                            <div class="col-9">
                        
                                <Card className="mt-4">
                                    <Card.Body>
                                        <>
                                            <div class="row mt-2">
                                                <div class="col fw-semibold fs-6"><i class="fa-solid fa-house"></i>&nbsp;&nbsp;{formatted_address}</div>
                                            </div>    
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <hr></hr>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-5">
                                                    <div class="row">
                                                        <div class="col-12 fw-semibold text-success">Use district zone</div>
                                                        <div class="col-12">{use_district}</div>
                                                    </div>   
                                                    <div class="row mt-2">
                                                        <div class="col-12 fw-semibold text-success">Height and bulk district</div>
                                                        <div class="col-12">{height_bulk}</div>
                                                    </div>   
                                                </div>
                                                <div class="col-7">
                                                    <div class="row">
                                                        <div class="col-12  fw-semibold text-success">Special districts</div>
                                                        <div class="col-12">
                                                            <ul>
                                                                {
                                                                    special_use_districts.map(special_district => {
                                                                        return <li> {special_district}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <hr></hr>
                                                </div>
                                            </div>
                                            <div class="row mt-2 topicSection">
                                                <div class="container">
                                                    <div class="row mb-3">
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('height')} onClick={()=>code_section_button_click('height')}>Height/bulk</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('setback')} onClick={()=>code_section_button_click('setback')} >Front setback</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('landscaping')} onClick={()=>code_section_button_click('landscaping')}>Landscaping</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('rear_yard')} onClick={()=>code_section_button_click('rear_yard')}>Rear yard</Button>
                                                            </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-3 text-center">
                                                        <Button className="topicButton" variant={button_variant('parking')} onClick={()=>code_section_button_click('parking')}>Parking</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('street_scape')} onClick={()=>code_section_button_click('street_scape')}>Streetscape</Button>
                                                        </div>
                                                        <div class="col-3 text-center"
                                                            ><Button className="topicButton" variant={button_variant('frontage')} onClick={()=>code_section_button_click('frontage')}>Frontage</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('open_space')} onClick={()=>code_section_button_click('open_space')}>Open space</Button>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('area_ration')}>Area ratio</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <Button className="topicButton" variant={button_variant('density')}>Density</Button>
                                                        </div>
                                                        <div class="col-3 text-center"
                                                            ><Button className="topicButton" variant={button_variant('usage')}>Usage</Button>
                                                        </div>
                                                        <div class="col-3 text-center">
                                                        
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="row">
                                                        <div class="col-6 text-center">
                                                            <Button className="searchButton" variant={large_button_variant('documents')} onClick={ documents_button_click }>Relevant documents</Button>
                                                        </div>    
                                                        <div class="col-6 text-center">
                                                            <Button className="searchButton" variant={large_button_variant('question')} onClick={ question_click }>Ask a question</Button>
                                                        </div>    
                                                    </div>    
                                                </div>
                                            </div>
                                        </>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-3 readingList">
                                {
                                    reading_list.length > 0 && 
                                    <Card className="mt-4">
                                        <Card.Body>
                                            <div class="row  mb-2">
                                                <div class="col-auto fw-bold">Research list </div>
                                                <div class="col text-end"><a role="button" href="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/san_francisco-ca-2+(3).pdf" target="_blank"><i class="fa-solid fa-download"></i></a></div>
                                            </div>
                                            {reading_list.map(section => { 
                                                return  <>  
                                                            <div class="row mt-1"><div class="col"><hr></hr></div></div>
                                                            <div class="row">
                                                                <div class="col text-truncate" role="button"><small>{section}</small></div>
                                                            </div>
                                                        </>
                                                })}
                                        </Card.Body>
                                    </Card>
                                }
                            </div>                                          
                        </div>
                    }
                    {
                        show_code_section_component && 
                        <div class="row">
                            <div class="col-9">
                                <CodeSectionsComponent 
                                    code_sections={active_code_sections}
                                    formatted_address={formatted_address}
                                    show_documents={show_documents}
                                    addToReadingList={addToReadingList}
                                    setShowDefinition={setShowDefinition}
                                />
                            </div>
                            <div class="col-3 readingList">
                                {
                                    show_definition && 
                                        <Card className="mt-4">
                                            <Card.Body>
                                                <div class="row"><div class="col"><strong>Awning.</strong> A light roof-like structure, supported entirely by the exterior wall of 
                                                    a building; consisting of a fixed or movable frame covered with cloth, plastic, or metal; 
                                                    extending over doors, windows, and/or show windows; with the purpose of providing protection 
                                                    from sun and rain and/or embellishment of the façade; as further regulated in Section 3105 of the Building Code.
                                                </div></div>
                                            </Card.Body>
                                        </Card>
                                }
                            </div>               
                        </div>
                    }
                    { 
                        show_search_section_component &&
                        <div class="row">
                            <div class="col-9">
                                <SearchComponent />
                            </div>
                            <div class="col-3 ">
                            </div>       
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

class AddressSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchButtonEnabled: false,
        }

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.resetAddressField = this.resetAddressField.bind(this);
    }
      
    addressChanged(e) {
        this.setState({
            searchButtonEnabled: false,
            address_field: e.target.value,
        });
    }

    resetAddressField() {
        this.autocompleteInput.current.value = "";
        this.setState({
            searchButtonEnabled: false,
        });
    }

    componentDidMount() {

        const center = { lat: 37.75953994968017, lng: -122.44697911726526 };

        // Create a bounding box with sides ~10km away from the center point
        const defaultBounds = {
            north: center.lat + 0.07,
            south: center.lat - 0.07,
            east: center.lng + 0.07,
            west: center.lng - 0.07,
        };
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
            {"types": ["geocode"], "fields": ["address_components", "formatted_address", "name", "geometry"], bounds: defaultBounds, strictBounds: true });

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        let streetNumber = '';
        let streetName = ''
        let city = '';
        let state = '';
        let zipCode = '';

        let i = 0;
        let lat = place.geometry.location.lat();
        let long = place.geometry.location.lng();

        for(; i < place.address_components.length; i++) {
            let component = place.address_components[i];
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            else if (component.types.includes("route")) {
                streetName = component.long_name;
            }
            else if (component.types.includes("locality")) {
                city = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
            }
            else if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
            }
        }

        this.props.setAddress({
            street_number: streetNumber,
            street_name: streetName,
            city: city,
            state: state,
            zip_code: zipCode,
            formatted_address: place.formatted_address,
            lat: lat,
            long: long,
        });

        this.setState({
            searchButtonEnabled: true,
        })
    }

    render() {
        const { searchButtonClick, searching_address } = this.props;
        
        return(
            <div class="container addressContainer">
                <div class="row gy-3">
                    <div class="col-12 col-md-9">
                        <div class="input-group">
                            <input type="text" class="form-control" placeHolder="address" ref={this.autocompleteInput}  id="autocomplete" onChange={ (e) => this.addressChanged(e) }/>
                            <Button className="bg-transparent text-dark clearButton" onClick={this.resetAddressField}>
                                <i class="fa fa-times"/>
                            </Button>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        {searching_address? 
                            <Button variant="primary" disabled className="addressButton">
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                &nbsp;&nbsp;Searching...
                            </Button>
                        :
                            <Button variant="primary" className="addressButton" disabled={ !this.state.searchButtonEnabled } onClick={ searchButtonClick }>
                                Search
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function SearchComponent (props) {
    const [text_box_text, setTextBoxText] = useState('');
    const [result, setResult] = useState('');
    const [waiting_for_result, setWaitingForResult] = useState(false);



    const search = () => {
        setResult('');
        setWaitingForResult(true);

        const interval = setTimeout(() => {
            setWaitingForResult(false);
            
            if (text_box_text.includes('slope')){
                setResult('answer_2');
            } 
            else if (text_box_text.includes('elevator')){
                setResult('answer_1');
            }
            else if (text_box_text.includes('infill')){
                setResult('answer_3');
            }
        }, 2000);
    }

    return (
        <div class="container searchComponent  mt-4">
            <div class="row">
                <div class="input-group">
                    <input type="search" 
                        placeHolder="Type your question"
                        class="form-control"
                        name="search" 
                        id="search"
                        value={text_box_text}
                        onChange={ (e) => setTextBoxText(e.target.value) }
                    />
                    {   waiting_for_result ?
                            <Button variant="warning" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Searching...
                            </Button>
                        :
                        <Button variant="warning" onClick={search}><i class="fa-regular fa-paper-plane"></i></Button>    
                    }
                    
                </div>
            </div>
            
            <div class="row mt-4 p-3">
                {
                    result && <div class="col">
                        {
                            result === 'answer_1' &&
                            <Answer_1/>
                        }
                        {
                            result === 'answer_2' &&
                            <Answer_2/>
                        }
                        {
                            result === 'answer_3' &&
                            <Answer_3/>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

function PlanningProjectDetails(props){

    const { setHeightBulk, setUseDistrict, setSpecialUseDistricts, setFormattedAddress, setAllCodeSections, setStage} = props;
    const [current_page, setCurrentPage] = useState('start');
    const [address, setAddress] = useState(null);
    const [searching_address, setSearchingAddress] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const submit = () => {
        
        setSubmitting(true);
        setTimeout(() =>      
            {setSubmitting(false); setStage(Stages.RESULT) },
            2000
        );
    }
    const searchButtonClick = () => {
        setSearchingAddress(true);

        let request = {
            "request_type": 'property_info',
            "request_address": address,
        }

        generic_post_api(request, PLANNING_PROPERTY_INFO)
        .then(
            result => {
                return result.data.json();
               
            })
        .then(data => {
            setFormattedAddress(data.formatted_address);
            setHeightBulk(data.height_bulk);
            setUseDistrict(data.use_district);
            setSpecialUseDistricts(data.special_use_districts);

            heightButtonClick(data.formatted_address, data.height_bulk, data.use_district, data.special_use_districts);
        })
        .catch(error => {
            setSearchingAddress(false);
        });
    }

    const heightButtonClick = (address, height, use, special) => {

        let request = {
            "request_type": 'height_info',
            'height_bulk': height,
            'use_district': use,
            'special_use_districts': special,
            'formatted_address': address,
        }

        generic_post_api(request, PLANNING_PROPERTY_INFO)
        .then(
            result => {
                return result.data.json();
               
            })
        .then(data => {
            setAllCodeSections(data);
            setSearchingAddress(false);
            setCurrentPage('project');
        })
        .catch(error => {
        });
    }

    return(
        <div class="planningProjectDetails container">
             <div class="row">
                <div class="col text-center display-6">Property &amp; project details</div>
            </div>
            { current_page === 'start' &&
                <div class="row mt-4">
                    <div class="col">
                        <Card>
                            <Card.Body>
                                <Card.Text className="ms-3 fw-semibold text-center">
                                    <p>Start with the project name</p>
                                </Card.Text>
                                <div class="container">
                                    <div class="row">
                                        <div class="col"><input type="text" class="form-control" placeHolder="project name"/></div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-end">
                                            <Button variant="primary" onClick={()=> setCurrentPage('address')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>    
                    </div>
                </div>
            }
            {
                current_page === 'address' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="ms-3 fw-semibold text-center">
                                <p>Property's address</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row ">
                                <AddressSection 
                                    setAddress={ setAddress }
                                    searchButtonClick={ searchButtonClick }
                                    searching_address={ searching_address }
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                current_page === 'project' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="ms-3 fw-semibold text-center">
                                <p>Select all that apply to your project</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="horizontal">
                                                    <ToggleButton className="text-nowrap projectButton" id="horizontal-radio-1" value={1} variant="outline-success"
                                                        > Horizontal addition  
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="vertical">
                                                    <ToggleButton className="text-nowrap projectButton" id="vertical-radio-1" value={1} variant="outline-success"
                                                        > Vertical addition  
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="ADU">
                                                    <ToggleButton className="text-nowrap projectButton" id="ADU-radio-1" value={1} variant="outline-success"
                                                        > New ADU  
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="dwelling">
                                                    <ToggleButton className="text-nowrap projectButton" id="dwelling-radio-1" value={1} variant="outline-success"
                                                        > New dwelling unit
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="garage">
                                                    <ToggleButton className="text-nowrap projectButton" id="garage-radio-1" value={1} variant="outline-success"
                                                        > New garage/parking
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            <div class="col-auto">
                                                <ToggleButtonGroup className="mb-2" type="checkbox" name="use">
                                                    <ToggleButton className="text-nowrap projectButton" id="use-radio-1" value={1} variant="outline-success"
                                                        > Change of use
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('lot')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {/* {
                current_page === 'height' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="ms-3 fw-semibold text-center">
                                <p>Height &amp; bulk</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col-6">Current property height</div>
                                    <div class="col-2"><input type="text" class="form-control" /></div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Proposed property height</div>
                                    <div class="col-2"><input type="text" class="form-control"/></div>
                                </div>
                                
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('lot')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            } */}
            {
                current_page === 'lot' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="ms-3 fw-semibold text-center">
                                <p>Lot information</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col-6">Is the property on a narrow street</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="narrow">
                                            <ToggleButton
                                                id="tbg-radio-1" value={1}
                                                variant="outline-success"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="tbg-radio-2" value={2}
                                                variant="outline-success"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="tbg-radio-3" value={3}
                                                variant="outline-success"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Is the property on a slope</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="slope">
                                            <ToggleButton
                                                id="slope-radio-1" value={1}
                                                variant="outline-success"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="slope-radio-2" value={2}
                                                variant="outline-success"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="slope-radio-3" value={3}
                                                variant="outline-success"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Is the lot vacant</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="vacant">
                                            <ToggleButton
                                                id="vacant-radio-1" value={1}
                                                variant="outline-success"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="vacant-radio-2" value={2}
                                                variant="outline-success"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="vacant-radio-3" value={3}
                                                variant="outline-success"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Is this a corner lot</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="corner">
                                            <ToggleButton
                                                id="corner-radio-1" value={1}
                                                variant="outline-success"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="corner-radio-2" value={2}
                                                variant="outline-success"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="corner-radio-3" value={3}
                                                variant="outline-success"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                {/* <div class="row mt-3">
                                    <div class="col-6">Do the lot lines converge</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="converge">
                                            <ToggleButton
                                                id="converge-radio-1" value={1}
                                                variant="outline-success"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="converge-radio-2" value={2}
                                                variant="outline-success"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="converge-radio-3" value={3}
                                                variant="outline-success"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div> */}
                                <div class="row mt-5">
                                    <div class="col-12 text-end"><Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={()=> setCurrentPage('frontage')}>Next&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                current_page === 'frontage' && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="ms-3 fw-semibold text-center">
                                <p>Frontage &amp; landscaping</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row mt-3">
                                    <div class="col-6">Are you adding more than 20% of new habitable space</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="habitable">
                                            <ToggleButton
                                                id="habitable-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="habitable-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="habitable-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Repaving more than 200sqf of front area</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="radio" name="Repaving">
                                            <ToggleButton
                                                id="Repaving-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Yes  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Repaving-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > No    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Repaving-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Don't know  
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">Adding any of the following</div>
                                    <div class="col-6">
                                        <ToggleButtonGroup className="mb-2" type="checkbox" name="awning">
                                            <ToggleButton
                                                id="awning-radio-1" value={1}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Awning  
                                            </ToggleButton>
                                            <ToggleButton
                                                id="awning-radio-2" value={2}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Canopy    
                                            </ToggleButton>
                                            <ToggleButton
                                                id="awning-radio-3" value={3}
                                                variant="outline-success"
                                                className="frontageButton"
                                                > Marquee
                                            </ToggleButton>
                                            
                                            </ToggleButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 text-end">
                                        <Button variant="secondary">Skip</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {
                                            !submitting ? 
                                                <Button variant="primary" onClick={submit}>Submit&nbsp;&nbsp;<i class="fa-solid fa-arrow-right"></i></Button>
                                            :
                                                <Button variant="primary" onClick={submit}>Submitting&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></Button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
        </div>
    )
}

function Section132_1(props) {
    const [visible_parts, setVisibleParts] = useState({});

    const setVisible = (part, value) => {
        setVisibleParts(prevState => ({
            ...prevState,
            [part]: value,
        }));
    }

    return(
        <div class="container">
            <div class="row mb-3"><div class="col"><a role="button" className="text-decoration-underline" onClick={()=> {setVisible('section_1', true); setVisible('section_2', true); setVisible('section_3', true)}}>View entire text</a></div></div>
            <div class="row">
                <div class="sectionSummary col">
                    <div class="sp">
                        <div>The following requirements for minimum front setback areas shall apply to every building in all RH, RTO,
                            and RM Districts, in order to relate the setbacks provided to the existing front setbacks of adjacent
                            buildings. Buildings in RTO Districts which have more than 75 feet of street frontage are additionally
                            subject to the Ground Floor Residential Design Guidelines, as adopted and periodically amended by the
                            Planning Commission.
                            {
                                ! visible_parts.section_1 && 
                                <span>&nbsp;Planned Unit Developments&nbsp;
                                    <a role="button" onClick={() => setVisible('section_1', true)}><i class="fa-regular fa-square-caret-down fa-lg"></i></a>
                                </span>
                            }
                            
                            {
                                visible_parts.section_1 && 
                                <span className="bg-warning"> 
                                    Planned Unit Developments&nbsp;or PUDs, as defined in Section 304, shall also provide
                                    landscaping in required setbacks in accord with Section 132(g). 
                                    <a role="button" onClick={() => setVisible('section_1', false)}>&nbsp;<i class="fa-regular fa-square-caret-up fa-lg"></i></a>
                                </span>
                            }
                            
                        </div>
                        <ol type="a">
                            <li><strong>Basic Requirement.</strong> Where one or both buildings adjacent to the subject property have
                                front setbacks along a Street or Alley, any building or addition constructed, reconstructed, or
                                relocated on the subject property shall be set back to no less than the depth of the adjacent building
                                with the shortest front setback, except as provided in subsection (c). In any case in which the lot
                                constituting the subject property is separated from the lot containing the nearest building by an
                                undeveloped lot or lots for a distance of 50 feet or less parallel to the Street or Alley, such nearest
                                building shall be deemed to be an “adjacent building,” but a building on a lot so separated for a
                                greater distance shall not be deemed to be an “adjacent building.”
                            </li>
                            <li><strong>Method of Measurement.</strong> The extent of the front setback of each adjacent building shall
                                be taken as the horizontal distance from the property line along the Street or Alley to the building
                                wall closest to such property line, excluding all projections from such wall, all decks and garage
                                structures and extensions, and all other obstructions.
                            </li>
                            <li><strong>Applicability to Special Lot Situations.</strong>
                                <ol type="1">        
                                {
                                ! visible_parts.section_2 && 
                                    <li><strong>Corner Lots and Lots at Alley Intersections.</strong>&nbsp;<a role="button" onClick={() => setVisible('section_2', true)}><i class="fa-regular fa-square-caret-down fa-lg"></i></a></li>
                                }
                                {
                                visible_parts.section_2 && 
                                    <li>
                                        <span className="bg-warning"><strong>Corner Lots and Lots at Alley Intersections.</strong>&nbsp;On a Corner Lot as defined in
                                        Section 102 of this Code, or a lot at the intersection of a Street and an Alley or two Alleys, a
                                        front setback area shall be required only along the Street or Alley elected by the owner as the
                                        front of the property. Along such Street or Alley, the required setback for the subject lot
                                        shall be equal to one-half the front setback of the adjacent building.
                                        <a role="button" onClick={() => setVisible('section_2', false)}>&nbsp;<i class="fa-regular fa-square-caret-up fa-lg"></i></a>
                                        </span>
                                    </li>
                                }
                                    
                                    <li><strong>Lots Abutting Properties That Front on Another Street or Alley.</strong> In the case of
                                        any lot that abuts along its side lot line upon a lot that fronts on another Street or Alley,
                                        the lot on which it so abuts shall be disregarded, and the required setback for the subject lot
                                        shall be equal to the front setback of the adjacent building on its opposite side.
                                    </li>
                                    <li><strong>Lots Abutting RC, C, M, and P Districts.</strong> In the case of any lot that abuts
                                        property in an RC, C, M, or P District, any property in such district shall be disregarded, and
                                        the required setback for the subject lot shall be equal to the front setback of the adjacent
                                        building in the RH, RTO, or RM District.
                                    </li>
                                </ol>
                            </li>
                            <li><strong>Maximum Requirements.</strong> The maximum required front setback in any of the cases described
                                in this Section 132 shall be 10 feet from the property line along the Street or Alley, except in cases
                                where more than 75% of the properties on the subject block face have a setback of 15 feet or greater,
                                and both parcels adjacent to the parcel property have a front setback of 15 feet or greater, in which
                                case the maximum front setback shall be 15 feet. The required setback for lots located within the Bernal
                                Heights Special Use District is set forth in Section 242 of this Code.
                            </li>
                            <li><strong>Permitted Obstructions.</strong> Only those obstructions specified in Section 136 of this Code
                                shall be permitted in a required front setback area, and no other obstruction shall be constructed,
                                placed or maintained within any such area. No motor vehicle, trailer, boat or other vehicle shall be
                                parked or stored within any such area, except as specified in Section 136.
                            </li>
                            <li><strong>Landscaping and Permeable Surfaces.</strong> The landscaping and Permeable Surface requirements
                                of this subsection (g) and subsection (h) below shall be met by the permittee in the case of
                                construction of a new building; the addition of a new Dwelling Unit, a garage, or additional parking;
                                any addition to a structure that would result in an increase of 20% or more of the existing Gross Floor
                                Area; a Residential Merger, as defined in Section 317; or paving or repaving more than 200 square feet
                                of the front setback. All front setback areas required by this Section 132 shall be appropriately
                                landscaped, meet any applicable water use requirements of Administrative Code Chapter 63, and in every
                                case not less than 20% of the required setback area shall be and remain unpaved and devoted to plant
                                material, including the use of climate appropriate plant material as defined in Public Works Code
                                Section 802.1. For the purposes of this Section 132, permitted obstructions as defined by Section
                                136(c)(6) chimneys, Section 136(c)(14) steps, and Section 136(c)(27) garages in steeply sloping front
                                setbacks shall be excluded from the front setback area used to calculate the required landscape and
                                Permeable Surface area. If the required setback area is entirely taken up by one or more permitted
                                obstructions, the Zoning Administrator may allow the installation of sidewalk landscaping that is
                                compliant with applicable water use requirements of Chapter 63 of the Administrative Code to satisfy the
                                requirements of this Section 132, subject to permit approval from the Department of Public Works in
                                accordance with Public Works Code Section 810B.
                            </li>
                            <li><strong>Permeable Surfaces.</strong> The front setback area shall be at least 50% permeable so as to
                                increase stormwater infiltration. The Permeable Surface may be inclusive of the area counted towards the
                                landscaping requirement; provided, however, that turf pavers or similar planted hardscapes shall be
                                counted only toward the Permeable Surface requirement and not the landscape requirement.
                                <ol type="1">
                                    <li>The Zoning Administrator, after consultation with the Director of Public Works, may waive the
                                        Permeable Surface requirement if the site does not qualify as a suitable location pursuant to
                                        Department of Public Works rules and regulations.
                                    </li>
                                    <li>If the site receives stormwater run-off from outside the lot boundaries, the Zoning
                                        Administrator, after consultation with the General Manager of the Public Utilities Commission,
                                        may modify the Permeable Surface requirement to include alternative management strategies, such
                                        as bio-retention or other strategies, pursuant to Public Utilities Commission rules and
                                        regulations.
                                    </li>
                                </ol>
                            </li>
                            {
                                ! visible_parts.section_3 && 
                                <li><strong>Planned Unit Developments.&nbsp;</strong><a role="button" onClick={() => setVisible('section_3', true)}><i class="fa-regular fa-square-caret-down fa-lg"></i></a></li>
                            }
                            {
                                visible_parts.section_3 && 
                                    <li><span className="bg-warning"><strong>Planned Unit Developments.</strong> In addition to the front yard landscaping requirements in Section 132(g).
                                        Planned Unit Developments are required to install the following front yard landscape features.</span>
                                        <ol type="1">
                                            <li><span className="bg-warning">Where ground floor setbacks are required, landscaping is also required in the setbacks per
                                                Section 132(g). All building setback areas not occupied by steps, porches or other permitted
                                                obstructions shall be Permeable Surfaces. Setbacks should be designed to provide access to
                                                landscaped areas, encouraging active use by residents.</span>
                                                <ol type="A">
                                                    <li><span className="bg-warning">A water source should be provided for each residential setback reachable by a 30-foot
                                                        hose.</span>
                                                    </li>
                                                    <li><span className="bg-warning">To allow for landscaping and street trees at street grade, below-grade parking shall be
                                                        located at a depth below any surface of the setback to provide a minimum soil depth of 3
                                                        feet 6 inches.</span>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><span className="bg-warning">The Zoning Administrator is authorized to modify the additional landscaping requirements for
                                                Planned Unit Developments. The Zoning Administrator shall allow modifications only when he or
                                                she finds that modifications provide equal or greater ecological benefit than the above
                                                requirements, including the use of climate appropriate plant materials as defined in Public
                                                Works Code Section 802.1. Acceptable modifications may include alternative landscape treatments
                                                such as landscaped berms, detention or retention basins, perimeter plantings, pedestrian
                                                lighting, benches and seating areas, or additional landscaping and tree planting elsewhere on
                                                the site or on the adjacent public right-of-way itself, subject to permit approval from the
                                                Department of Public Works.</span>
                                                <a role="button" onClick={() => setVisible('section_3', false)}>&nbsp;<i class="fa-regular fa-square-caret-up fa-lg"></i></a>
                                            </li>
                                        </ol>
                                        
                                    </li>
                             }
                            <li>Relationship to Legislated Setback Lines. In case of any conflict between the requirements of this
                                Section 132 for front setback areas and a legislated setback line as described in Section 131 of this
                                Code, the more restrictive requirements shall prevail.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Section_136_1_summary(props){
    return <div class="sectionSummary">
        <div class="sp">
            <div><strong>Awning Regulations:</strong></div>
                <div><strong>Height and Placement: </strong><span onClick={()=> props.setShowDefinition(true)} role="button">Awnings <i class="fa-regular fa-comment-dots" style={{color: '#d32727'}}></i></span> must be at least eight feet above the finished grade, with valances at least seven feet above. Awnings cannot 
                        extend above the windowsill of the lowest story, cover any architectural features like cornices, or exceed a height of 16 feet or the roofline 
                        of the building, whichever is lower. Awnings must not cover external piers or columns that define storefront bays.
                </div>
                    <ul>
                        <li>For dwellings in specific districts, no more than one-third of the ground story width along the front or street side lot line, or a setback building 
                            wall, can be devoted to off-street parking entrances. However, lots are not limited to a single entrance less than 10 feet wide (8 feet in RTO and 
                            RTO-M districts). No parking entrance can be wider than 20 feet, and there must be at least six feet between multiple entrances. In RTO and RTO-M 
                            districts, a maximum of 20 feet per block frontage can be used for parking entrances. Garage structures and doors must align with the primary 
                            building facade unless consistent with Section 136. Entrances must be at least six feet from lot corners at public right-of-way intersections.
                        </li>
                        <li><strong>Exceptions: </strong>These requirements don't apply if the lot has a slope greater than 20% from the front lot line to the rear yard or 
                            if the permitted building depth is less than 40 feet in RH-2 Districts or less than 65 feet in RH or RM Districts.
                        </li>
                    </ul>
        </div>
            <div class="sp">
                <div><strong>Size Limits Based on Width</strong></div>
                <ul><li>For awnings up to 10 feet wide along the street, the horizontal projection can extend up to six feet from the building,
                     with a maximum vertical distance of six feet.</li>
                <li>For awnings wider than 10 feet, the horizontal projection is limited to four feet, with a maximum vertical distance of four feet.</li>
                </ul>
            </div>
        </div>
}

function Section_134() {
    return( <div class="sub-section-container">
            <ol type="a">
                <li> Purpose. The rear yard requirements of this Section 134 are intended to:
                    <ol type="1">
                        <li>assure the protection and continuation of established mid-block landscaped open spaces;</li>
                        <li>maintain a scale of development appropriate to each district, complementary to the location of
                            adjacent
                            buildings;
                        </li>
                        <li>provide natural light and natural ventilation to residences, work spaces, and adjacent rear yards;
                            and
                        </li>
                        <li>provide residents with usable open space and views into green rear-yard spaces.</li>

                    </ol>
                </li>
                <li> Applicability. The rear yard requirements established by this Section 134 shall apply to every building in
                    the
                    districts listed below. To the extent that these provisions are inconsistent with any Special Use District
                    or
                    Residential Character District, the provisions of the Special Use District or Residential Character District
                    shall
                    apply.
                </li>

                <li>Basic Requirements. The basic rear yard requirements shall be as follows for the districts indicated:
                    <ol>
                        <li>In RH, RM-1, RM-2, RTO, RTO-M Zoning Districts, the basic rear yard shall be equal to 30% of the
                            total depth of
                            the lot on which the building is situated, but in no case less than 15 feet.
                        </li>
                        <li>In all other Zoning Districts not listed in subsection (c)(1), the rear yard shall be equal to 25%
                            of the total
                            depth of the lot on which the building is situated, but in no case less than 15 feet.
                        </li>
                    </ol>
                </li>
                <li>
                    Rear Yard Location Requirements.
                    <ol>
                        <li>RH, RM, RTO, NC-1, NCT-1, Inner Sunset, Outer Clement Street, Cole Valley, Haight Street, Lakeside
                            Village,
                            Sacramento Street, 24th Street-Noe Valley, Pacific Avenue, and West Portal Avenue Districts. Rear
                            yards shall be
                            provided at grade level and at each succeeding level or story of the building.
                        </li>
                        <li>NC-2, NCT-2, Ocean Avenue, Inner Balboa Street, Outer Balboa Street, Castro Street, Cortland Avenue,
                            Divisadero
                            Street NCT, Excelsior-Outer Mission Street, Inner Clement Street, Upper Fillmore Street, Lower
                            Haight Street, Judah
                            Street, Noriega Street, North Beach, San Bruno Avenue, Taraval Street, Inner Taraval Street, Irving
                            Street, Union
                            Street, Valencia Street, 24th Street-Mission, Glen Park, Regional Commercial District and Folsom
                            Street Districts.
                            Rear yards shall be provided at the second story, and at each succeeding story of the building, and
                            at the First
                            Story if it contains a Dwelling Unit.
                            <div class="diagram"><img
                                    src="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/0-0-0-5524.jpg"/></div>
                        </li>
                        <li>RC-3, RC-4, NC-3, NCT-3, Bayview, Broadway, Fillmore Street, Geary Boulevard, Hayes-Gough,
                            Japantown, SoMa NCT, Mission Bernal, Mission Street, Polk Street, Lower Polk Street, Pacific Avenue,
                            M, SPD, MUR, MUG, MUO, and UMU Districts. Rear yards shall be provided at the lowest story
                            containing a Dwelling Unit, and at each succeeding level or story of the building. In the
                            Hayes-Gough NCT, lots fronting the east side of Octavia Boulevard between Linden and Market Streets
                            (Central Freeway Parcels L, M, N, R, S, T, U, and V) are not required to provide rear yards at any
                            level of the building, provided that the project fully meets the usable open space requirement for
                            Dwelling Units pursuant to Section 135, meets the exposure requirements of Section 140, and gives
                            adequate architectural consideration to the light and air needs of adjacent buildings given the
                            constraints of the project site.
                        </li>
                        <li>Upper Market Street NCT. Rear yards shall be provided at the grade level, and at each succeeding
                            story of the building. For buildings in the Upper Market Street NCT that do not contain Residential
                            Uses and that do not abut adjacent lots with an existing pattern of rear yards or mid-block open
                            space, the Zoning Administrator may waive or reduce this rear yard requirement pursuant to the
                            procedures of subsection (h).
                        </li>
                        <li>RED, RED-MX and WMUG Districts. Rear yards shall be provided at the ground level for any building
                            containing a Dwelling Unit, and at each succeeding level or story of the building.
                        </li>
                    </ol>

                </li>
                <li>Permitted Obstructions. Only those obstructions specified in Section 136 of this Code shall be permitted in
                    a required rear yard, and no other obstruction shall be constructed, placed, or maintained within any such
                    yard. No motor vehicle, trailer, boat, or other vehicle shall be parked or stored within any such yard,
                    except as specified in Section 136.
                </li>
                <li>Second Building on Corner Lots and Through Lots in RH, RTO, RTO-M, RM-1, and RM-2 Districts. Where a lot is
                    a Corner Lot, or is a through lot having both its front and its rear lot line along Streets, Alleys, or a
                    Street and an Alley, the subject lot may have two buildings, each fronting at one end of the lot, provided
                    that all the other requirements of this Code are met. In such cases, the rear yard required by this Section
                    134 for the subject lot shall be located in the central portion of the lot, between the two buildings on
                    such lot. In no case shall the total minimum rear yard for the subject lot be thus reduced to less than a
                    depth equal to 30% of the total depth of the subject lot or to less than 15 feet, whichever is greater;
                    provided, however, that the Zoning Administrator may reduce the total depth to 20% pursuant to Section
                    307(l) of this Code if the reduction is for the sole purpose of constructing an Accessory Dwelling Unit
                    under Section 207.1, and provided further that the reduction/waiver is in consideration of the property
                    owner entering into a Regulatory Agreement pursuant to Section 207.1 subjecting the ADU to the San Francisco
                    Rent Stabilization and Arbitration Ordinance. For buildings fronting on a Narrow Street as defined in
                    Section 261.1 of this Code, the additional height limits of Section 261.1 shall apply. Furthermore, in all
                    cases in which this subsection (f) is applied, the requirements of Section 132 of this Code for front
                    setback areas shall be applicable along both Street or Alley frontages of the subject through lot.
                </li>
                <li>Reduction of Requirements in C-3 Districts. In C-3 Districts, an exception to the rear yard requirements of
                    this Section 134 may be allowed, in accordance with the provisions of Section 309, provided that the
                    building location and configuration assure adequate light and air to windows within the residential units
                    and to the usable open space provided.
                    <div class="diagram"><img
                            src="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/0-0-0-5534.jpg"/></div>
                </li>
                <li>Corner Lots and Lots at Alley Intersections. On a Corner Lot as defined in Section 102 of this Code, or on a
                    lot at the intersection of a Street and an Alley of at least 25 feet in width, the required rear yard may be
                    substituted with an open area equal to the basic rear yard requirement outlined in subsection (c) above at
                    the same levels as the required rear yard in an interior corner of the lot, an open area between two or more
                    buildings on the lot, or an inner court, as defined by this Code, provided that the Zoning Administrator
                    determines that all of the criteria described below in this Section 134 are met.
                    <ol>
                        <li>Each horizontal dimension of the open area shall be a minimum of 15 feet.</li>
                        <li>The open area shall be wholly or partially contiguous to the existing midblock open space formed by
                            the rear yards of adjacent properties.
                        </li>
                        <li>The open area will provide for the access to light and air to and views from adjacent properties.
                        </li>
                        <li>The proposed new or expanding structure will provide for access to light and air from any existing
                            or new residential uses on the subject property.
                            The provisions of this subsection (h) shall not restrict the discretion of the Zoning Administrator
                            from imposing such additional conditions as the Zoning Administrator deems necessary to further the
                            purposes of this Section 134.
                        </li>
                    </ol>
                </li>
                <li>
                    Modification of Requirements in the Eastern Neighborhoods Mixed Use Districts. The rear yard requirement in
                    Eastern Neighborhoods Mixed Use Districts may be modified or waived by the Planning Commission pursuant to
                    Section 329. The rear yard requirement in Eastern Neighborhoods Mixed Use Districts may be modified by the
                    Zoning Administrator pursuant to the procedures set forth in Section 307(h) for other projects, provided
                    that:

                    <ol>
                        <li>A comparable, but not necessarily equal amount of square footage as would be created in a code
                            conforming rear yard is provided elsewhere within the development;
                        </li>
                        <li>The proposed new or expanding structure will not significantly impede the access to light and air
                            from adjacent properties or adversely affect the interior block open space formed by the rear yards
                            of adjacent properties; and
                        </li>
                        <li>The modification request is not combined with any other residential open space modification or
                            exposure variance for the project, except exposure modifications in designated landmark buildings
                            under Section 307(h)(1).
                        </li>
                    </ol>
                </li>
                <li>
                    Reduction of Requirements in the North of Market Residential Special Use District. The rear yard requirement
                    may be substituted with an equivalent amount of open space situated anywhere on the site, provided that the
                    Zoning Administrator determines that all of the following criteria are met:

                    <ol>
                        <li>The substituted open space in the proposed new or expanding structure will improve the access of
                            light and air to and views from existing abutting properties; and
                        </li>
                        <li> The proposed new or expanding structure will not adversely affect the interior block open space
                            formed by the rear yards of existing abutting properties.
                            This provision shall be administered pursuant to the notice and hearing procedures which are
                            applicable to variances as set forth in Sections 306.1 through 306.5 and 308.2.
                        </li>
                    </ol>

                    <div class="diagram"><img
                            src="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/0-0-0-5536.jpg"/></div>
                    <div class="diagram"><img
                            src="https://renolition-public.s3.us-west-2.amazonaws.com/sf_code/0-0-0-5538.jpg"/></div>
                </li>
            </ol>

        </div>
    )
}

export default PlanningCodeSearch52;